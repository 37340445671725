export default {
  "TOP": "返回頂部"

  , "Head.Admin": "管理後臺"
  , "Head.Mine": "個人信息"
  , "Head.Lecturer": "講師專區"
  , "Head.Logout": "退出登錄"
  , "Head.Home": "首頁"
  , "Head.SelecLanguage": "選擇語言"

  , "Home.LearningDuration": "學習時長"
  , "Home.Tasks": "我的任務"
  , "Home.Course": "課程"
  , "Home.Project": "項目"
  , "Home.Exam": "考試"
  , "Home.SeeMore": "查看更多"
  , "Home.todoTips": "即將開始/進行中的考試、麵授和直播"
  , "Home.pcs": "個"
  , "Home.reload": "刷新"
  , "Home.init": "首頁數據初始化中，請稍等"
  , "Home.LearningDurationRanking": "學習時長排行榜"
  , "Home.AllRankings": "全部排行榜"
  , "Home.TodayLeaderboard": "今日排行榜"
  , "Home.WeekLeaderboard": "本週排行榜"
  , "Home.MonthLeaderboard": "本月排行榜"
  , "Home.WaitingInVain": "虛位以待"
  , "Home.inProgress": "進行中"
  , "Home.beginInAMinute": "即將開始"
  , "Home.ExamFrequency": "考試次數"
  , "Home.noLimit": "不限"
  , "Home.NumberOfParticipants": "參與人數"
  , "Home.FromEnd": "距結束"
  , "Home.FromStart": "距開始"
  , "Home.day": "天"
  , "Home.EnterImmediately": "立即進入"
  , "Home.NoIntroduction": "暫無簡介"
  , "Home.NoLecturer": "暫無講師"
  , "Home.introduce": "介紹"
  , "Home.None": "暫無"
  , "Home.NoContent": "暫無內容"
  , "Home.Newly": "新上架"
  , "Home.NoData": "冇有更多內容了"
  , "Home.TechnicalSupport": "技術支持"
  , "Home.AllRightsReserved": "版權所有"
  , "Home.notInit": "首頁數據未配置，請聯係管理員"

  , XB_PlatformSwitching: "平臺切換",
  XB_LarkLogin: "飛書登錄",
  XB_DingTalkLogin: "釘釘登錄",
  XB_WeComLogin: "企業微信登錄",
  XB_WeComH5Login: "企業微信H5登錄",
  XB_WechatLogin: "微信登錄",
  XB_PersonalRegistration: "個人註冊",
  XB_ExamAnswers: "考試答題",
  XB_LearnMapDetails: "學習地圖詳情",
  XB_LiveBroadcastRoom: "直播間",
  XB_UserAgreement: "用戶協議",
  XB_ErrorPage: "錯誤頁麵",
  XB_LoginPrompt: "登錄提示",
  XB_Preview: "預覽",
  XB_Unauthorized: "未獲得許可",
  XB_Study: "學習",
  XB_Resource: "資源",
  XB_ResourceCenter: "資源中心",
  XB_MyData: "我的知識",
  XB_NotFinishedLearning: "未學完",
  XB_PersonalCenter: "個人中心",
  XB_PopularKnowledge: "熱門知識",
  XB_LatestCourses: "最新課程",
  XB_LearningTask: "學習任務",
  XB_KnowledgeBase: "知識庫",
  XB_KnowledgeDetail: "知識詳情",
  XB_QuestionnaireSurvey: "調查問卷",
  XB_CourseCenter: "課程中心",
  XB_PagesNumber: "頁數",
  XB_ClearCache: "清除緩存",
  XB_StudyHoursRanking: "學時排行",
  XB_HasEnded: "已結束",
  XB_Search: "搜索",
  XB_My_CompleteCourse: "完成課程",
  XB_My_GetCredit: "獲得學分",
  XB_Home_LastLearned: "上次學到",
  XB_Home_EnterLearning: "進入學習",
  XB_Home_SeeMore: "查看更多",
  XB_More: "更多",
  XB_Home_ClickView: "點擊查看",
  XB_Home_Uptime: "上線時間",
  XB_Home_Instructor: "授課老師",
  XB_Home_Browse: "瀏覽",
  XB_Home_ReadingVolume: "閱讀量",
  XB_Home_To: "至",
  XB_LP_Stage: "第 {0} 階段",
  XB_LP_StudentStudiedTogether: "共 {0} 名學員一同學習",
  XB_LP_MyRankingIs: "我的排名是 {0}",
  XB_LP_DefeatedTheStudents: "打敗了 {0} 的學員",
  XB_PleaseAnswerTheQuestionFirst: "請回答下麵的問題後繼續學習",
  XB_AnswerTheQuestionFirst: "您已學習{0}分鐘，回答下麵的問題後繼續學習",
  XB_WarmTip: "溫馨提示",
  XB_ContinueToWatchTheCourse: "恭喜您答對了,請繼續觀看課程",
  XB_WrongSwitchNextQuestion: "此題答錯啦,請點擊確認切換下一題",
  XB_NumberOfParticipants: "參與人數",
  XB_NumberOfLearners: "學習人數",
  XB_Reward: "賞",
  XB_GetRewardedScores: "共獲得打賞積分",
  XB_InTotal: "共 {0} 條",
  XB_AvailableContent: "可獲內容",
  XB_AvailableHours: "可獲學時",
  XB_GetCredits: "可獲學分",
  XB_QuestionBank: "題庫",
  XB_Anonymous: "匿名",
  XB_Category: "類別",
  DR_Error_NoAuthorized: "對不起，您沒有參加當前考試的權限",
  DR_Error_NoAuthorizedByExercise: "對不起，您沒有參加當前練習的權限",
  XB_EntryType: "錄入類型",
  XB_Type: "類型",
  XB_LearningDuration: "學習時長",
  XB_ThematicDiscussionGroup: "專題討論組",
  XB_Personnel: "人員",
  XB_EnterDiscussionGroup: "進入討論組",
  XB_ForwardYourAnswer: "的提問期待你的解答",
  XB_Reply: "答覆",
  XB_LetMeSay: "我來說兩句...",
  XB_MyTotalIntegral: "我的總積分",
  XB_EarnPoints: "完成學習平臺任務獲得積分",
  XB_AcquisitionMethod: "獲取方式",
  XB_ImageUpload: "圖片上傳",
  XB_AllColleges: "全部學院",
  XB_IntroductionInstructions: "介紹說明",
  XB_MyPoints: "我的積分",
  XB_CommoditySurplus: "商品剩餘",
  XB_HasExchanged: "已兌換",
  XB_ExchangeQuantity: "兌換數量",
  XB_ConsumptionPoints: "消費積分",
  XB_PeopleHaveJoined: "{0}人已加入",
  XB_Group_GroupShortTitle: "這個小組還差一個標題了~",
  XB_Group_DescribeThisGroup: "來吧，給這個小組描述下吧...",
  XB_Group_TopicShortTitle: "這個話題還差一個標題了~",
  XB_Group_DescribeThisTopic: "來吧，給這個話題描述下吧...",
  XB_Signup: "報名",
  XB_ExamHasStarted: "考試已開始",
  XB_FaceSampling: "人臉採集",
  XB_Course_Lecturer: "講師",
  XB_LearningSituation: "學習情況",
  XB_Message: "消息",
  XB_TotalOf: "共有{0}條",
  Pub_Msg_DDM: "確定要刪除該成員？",
  Pub_Msg_DCUAJd: "確定取消該用戶管理員權限？",
  Pub_Msg_DUUpA: "確定該用戶升級成管理員？",
  Lab_Group_SetAdministrator: "設置管理員",
  Lab_Group_CancelAdministrator: "取消管理員",
  CM_Rule_ImageTopic: "圖片話題",
  LB_Group_ErrorMsg_TagDuplicate: "重複標籤",
  LB_Group_ErrorMsg_DescriptionMissing: "描述不能為空",
  LB_Group_SaveTag: "保存標籤",
  LB_Group_CustomTag: "自定義標籤",
  XB_Group_ChooseTagMax: "最多選擇5個",
  XB_Group_ChooseLabel: "選擇標籤",
  XB_Exam_PendingApproval: "待批閱",
  BN_Comm_Completed: "完成",
  BN_Comm_Edit: "編輯",
  BN_Settings_CancelBinding: "解除綁定",
  CM_Affirm: "確認",
  CM_All: "全部",
  CM_AllCollections: "全部收藏",
  CM_AlreadySignToday: "今天已簽到",
  CM_Answer: "已答",
  CM_Retract: "收起",
  CM_Current: "當前",
  CM_Correct: "答對",
  CM_Wrong: "答錯",
  CM_Audite: "是否審核",
  CM_Btnreset: "重置",
  CM_Cancel: "取消",
  CM_CancelCollection: "取消收藏成功！",
  CM_CancelCollectionFailure: "取消收藏失敗",
  CM_CancelFavoriteSuccess: "取消收藏成功",
  CM_CancelRegistration: "不可取消報名！",
  CM_CancelRegistrationSuccessful: "取消報名成功！",
  CM_CCondition: "完成條件",
  CM_Choose: "選修",
  CM_SignInNow: "立即簽到",
  CM_CollectionFailure: "收藏失敗",
  CM_CollectionSuccess: "收藏成功",
  CM_LikeSuccessful: "點贊成功",
  CM_CancelLike: "取消點贊",
  CM_Comment: "點評",
  CM_Completed: "已完成",
  CM_Compressed: "壓縮文件",
  CM_Confirm: "確定",
  CM_ConfirmPassword: "確認新密碼",
  CM_ContentLengthLong: "內容長度超出限製",
  CM_ConversionForSuccessful: "兌換成功！",
  CM_CT: "麵授培訓",
  CM_Curriculum: "在線課程",
  CM_CutNumber: "切屏次數或切屏時間超過限製,將強製交卷！",
  CM_DailyPointsRule: "每日簽到可獲得{0}積分",
  CM_Delete: "刪除",
  CM_Department: "部門",
  CM_DepartmentFillUp: "您所屬的部門報名人數已滿！",
  CM_Detail: "詳細",
  CM_Empty: "空空如也",
  CM_EmptyPassword: "密碼不能為空!",
  CM_EndTime: "結束時間",
  CM_EnterNewPassword: "輸入新密碼",
  CM_EnterOriginalPassword: "輸入原始密碼",
  CM_EnterQues: "提問",
  CM_EverythingIsShown: "已顯示所有內容",
  CM_PullUpLoadMore: "上拉加載更多",
  CM_Exam_CutChance: "請註意，切屏次數已用完，再切屏將會強製交卷!",
  CM_Exchange: "換一換",
  CM_Exist: "頁麵上存在未參與的調查項！",
  CM_Expired: "已過期",
  CM_FaceToFaceName: "麵授名稱",
  CM_Fail: "操作失敗",
  CM_FailDelete: "刪除失敗！",
  CM_FailUpload: "上傳失敗！",
  CM_FailureEnroll: "報名失敗！",
  CM_FillUp: "報名人數已滿！",
  CM_FinInscriptions: "報名結束！",
  CM_Finish: "完成",
  CM_Finished: "已完成",
  CM_GetScore: "得分",
  CM_GetSuccess: "領取成功！",
  CM_HadNotSubmit: "未提交",
  CM_HadSubmit: "已提交",
  CM_HasSignedUp: "已經報名！",
  CM_Header: "頭像",
  CM_Hour: "小時",
  CM_Index: "首頁",
  CM_IngenKlassificering: "頁麵上存在未評分的項，請為所有評分項評分！",
  CM_InitiationTask: "學習任務已開始，不能取消報名！",
  CM_IsSubmitted: "提交中...",
  CM_Join: "參加",
  CM_LB_AttachmentFile: "附件",
  CM_LB_Credit: "學分",
  CM_LB_Description: "描述",
  CM_LB_Name: "名稱",
  CM_LB_Progress: "進度",
  CM_LB_Prompt: "提示",
  CM_LB_Reviews: "評語",
  CM_LB_Save: "保存",
  CM_LB_Status_Null: "暫無",
  CM_LB_SubmitTime: "提交時間",
  CM_MyStudy: "我的學習",
  CM_Level: "等級",
  CM_Live: "直播",
  CM_Medal: "勳章",
  CM_Minute: "分鐘",
  CM_MobilePhone: "手機號碼錯誤！",
  CM_ModifyPassword: "修改密碼",
  CM_MostComments: "評論最多",
  CM_Name: "名稱",
  CM_NameSorting: "名稱排序",
  CM_Newest: "最新",
  CM_NoAnswer: "未答",
  CM_NoJoin: "未參加",
  CM_NoPass: "未通過",
  CM_NoRight: "您沒有部門報名範圍控製權限！",
  CM_NoTakePartIn: "未參與",
  CM_NotCompleted: "未完成",
  CM_NotLearning: "未學習",
  CM_OK: "確定",
  CM_Open: "開啟",
  CM_Pass: "通過",
  CM_PassStatus: "通過狀態",
  CM_Period: "學時",
  CM_Piece: "件",
  CM_PleaseEnterContent: "請輸入內容",
  CM_Points: "分",
  CM_PopularityRanking: "人氣排序",
  CM_PraiseMost: "點贊最多",
  CM_Public: "公開",
  CM_QA: "問答題",
  CM_QAAnswer: "回答",
  CM_QRCode: "二維碼",
  CM_Refuse: "拒絕",
  CM_RepeatRegistration: "已報名,不能重複報名！",
  CM_Reply: "回覆",
  CM_Required: "必修",
  CM_Rule_BestTopic: "精華話題",
  CM_Rule_CancelBest: "取消精華",
  CM_Rule_CancelTop: "取消置頂",
  CM_Save: "保存",
  CM_SearchCurriculumData: "搜索課程&知識",
  CM_Second: "秒",
  CM_Select: "請選擇",
  CM_SelectAll: "全選",
  CM_ServiceExperience: "服務經歷",
  CM_SignFail: "簽到失敗",
  CM_SignIn: "登錄",
  CM_SignOutFail: "簽退失敗",
  CM_SignOutSuccess: "簽退成功",
  CM_SignRules: "簽到規則",
  CM_SignSuccess: "簽到成功",
  CM_Signup_NotAllow: "您所屬部門不允許報名！",
  CM_Skip: "跳過",
  CM_SorryCommentsTooFast: "對不起，您發錶評論的頻率過快",
  CM_SortSort: "類別排序",
  CM_StartedYet: "報名還未開始！",
  CM_StartTime: "開始時間",
  CM_Status: "狀態",
  CM_StudyMode_Map: "路徑圖",
  CM_Submit: "提交",
  CM_SubmitFail: "提交失敗",
  CM_SubmitTime: "提交時間",
  CM_Success: "操作成功！",
  CM_SuccessfullyDelete: "刪除成功！",
  CM_Survey: "問卷",
  CM_TakePartIn: "已參與",
  CM_Task: "任務",
  CM_ThumbUpSuccess: "點贊成功！",
  CM_TimeDue: "取消報名時間到期！",
  CM_TimeIsNew: "時間最新",
  CM_Tip: "提示",
  CM_UnExpired: "未過期",
  CM_Unfinished: "未完成",
  CM_Unlimited: "不限",
  CM_UnSelectAll: "全不選",
  CM_UploadAttachment: "請上傳附件後再提交！",
  CM_UserName: "用戶名",
  CM_UserPassWord: "密碼",
  CM_ViewResult: "查看結果",
  CM_WorkExperience: "工作經歷",
  DR_Error_CourseIsEnd: "課程已結束",
  DR_Error_CourseIsNotBegin: "課程未開始",
  DR_Error_CourseIsNotCanStudy: "暫時不能學習",
  DR_Error_CourseIsPrecondition: "課程存在前置條件",
  DR_Error_CourseNeedSignUp: "課程未報名",
  DR_Error_CourseNotExists: "無任何課程信息",
  DR_Error_CourseStudyPermissionError: "對不起，您沒有當前課程的學習權限",
  DR_Error_CTIsConNotStudy: "此麵授未授權",
  DR_Error_DocumentCannotView: "無法查看當前格式的文檔",
  DR_Error_EndTimeFormatError: "結束時間格式錯誤",
  DR_Error_JsonFormatError: "輸入的字符串格式不正確",
  DR_Error_SearchParamConvertError: "搜索參數實體轉換錯誤",
  DR_Error_SearchParamIsEmpty: "搜索參數實體對象不能為空",
  DR_Error_StartTimeFormatError: "開始時間格式錯誤",
  KM_HaveRead: "已閱讀",
  KM_NotFinished: "未讀完",
  KM_Unread: "未閱讀",
  Lab_ChooseLanguage: "請選擇以下語言：",
  Lab_CourseDetail_Collect: "收藏",
  Lab_CourseDetail_CourseDetail: "課程詳情",
  Lab_Doc_JoinEvaluate: "參與評估",
  Lab_Evaluate_M_BaseInfo: "培訓基本信息",
  Lab_Evaluate_T_CanMulti: "可多選",
  Lab_Evaluate_T_Course: "培訓課程：",
  Lab_Evaluate_T_FeedbackInfo: "培訓反饋信息",
  Lab_Evaluate_T_Teacher: "培訓教師",
  Lab_Evaluate_T_TrainingAddress: "培訓地點",
  Lab_Evaluate_T_TrainingDate: "培訓時間",
  Lab_Evaluate_T_WebCastTime: "直播時間",
  Lab_Exam_UserScore: "{0} 分",
  Lab_ExamDetail_ReExamNum: "補考次數",
  Lab_ExamDetail_ViewResult: "查看答卷",
  Lab_Group_AllTopic: "所有話題",
  Lab_Group_ExitGroup: "退出小組",
  Lab_Group_GroupLeader: "組長",
  Lab_Group_HotGroup: "熱門小組",
  Lab_Group_MyGroup: "我的小組",
  Lab_Group_NewestTopic: "最新話題",
  Lab_Group_NotOpenToNonMembers: "隱私（該小組內的話題不對非小組成員公開）",
  Lab_Group_TopicList: "話題列錶",
  Lab_Homework_InputContent: "請輸入作業內容...",
  Lab_Homework_Submit: "提交作業",
  Lab_Knowledge_HotSearch: "熱門搜索",
  Lab_KnowledgeArticleDetail_Publish: "發錶",
  Lab_Lesson_AllNumber: "全部人數",
  Lab_Lesson_CorrectNumber: "答對人數",
  Lab_Lesson_CorrectRate: "正確率",
  Lab_Lesson_OpenState: "開啟狀態",
  Lab_Lesson_ParticipationPercentage: "參與百分比",
  Lab_Lesson_QuestionFull: "請將內容填寫完整",
  Lab_Lesson_RightKey: "正確答案",
  Lab_Lesson_SubmitNumber: "提交人數",
  Lab_Lesson_YourAnswer: "您的答案",
  Lab_Lesson_YouRight: "恭喜您答對了!",
  Lab_Lesson_YouWrong: "很遺憾您答錯了!",
  Lab_LessonDetail_T_LiveDetail: "直播詳情",
  Lab_LessonDetail_T_SignIn: "簽到",
  Lab_LessonDetail_T_SignOut: "簽退",
  Lab_LessonInteract_Closing: "完成條件",
  Lab_LessonInteract_Photograph: "拍照",
  Lab_LessonInteract_SignIn: "簽到",
  Lab_LessonInteract_SignOut: "簽退",
  Lab_Mall_Conversion: "立即兌換",
  Lab_MyExam_ExaminationHistory: "歷史考試",
  Lab_MyExam_InExamination: "正式考試",
  Lab_MyFavorite_CancelFavorite: "取消收藏",
  Lab_MyFavorite_FavoriteDate: "收藏時間",
  Lab_MyHonnor_Draw: "領取",
  Lab_MyIntegra_IntegralGrade: "積分等級",
  Lab_MyIntegra_T_GradeIntegral: "等級積分",
  Lab_MyIntegra_TotalScore: "總積分",
  Lab_MyIntegral_IntegralRule: "積分規則",
  Lab_MyIntegral_Onetime: "一次性  ",
  Lab_MyInteract_CommentDesc: "評價了",
  Lab_MyInteract_DiscussionGroup: "討論組",
  Lab_MyInteract_MyComment: "我的評論",
  Lab_MyInteract_MyReplay: "我的回覆",
  Lab_MyPlan_GetCredit: "獲得學分",
  Lab_MySignUp_T_Ranking: "排名",
  Lab_News_Cancel: "取消",
  Lab_News_News: "新聞",
  Lab_Signup_Msg_Audit: "您的報名正在審核",
  Lab_Signup_Msg_Success: "您的報名已成功",
  Lab_SignUp_Status: "報名狀態",
  Lab_Signup_T_CancelRegistration: "取消報名",
  Lab_Signup_T_GoSign: "我要報名",
  Lab_Subject_SynthesizeOrder: "綜合排序",
  Lab_Teacher_Fen: "分",
  LB_AboutInstructor: "講師介紹",
  LB_AccessibleIntegral: "可獲積分",
  LB_Account_BindAnotherWeiXin: "當前綁定賬號：{0}",
  LB_Account_EnterLogo: "請輸入企業標識",
  LB_Account_InputAccount: "請輸入用戶名",
  LB_Account_InputPassword: "請輸入密碼",
  LB_AllPhotos: "全部照片",
  LB_AlreadyThumbsUp: "您已點過贊！",
  LB_Appraiser: "評估人",
  LB_AReward: "打賞",
  LB_Arrange: "安排",
  LB_AttachmentView: "附件查看，請到電腦端操作",
  LB_AudioFrequency: "音頻",
  LB_AuditResult: "審核結果",
  LB_AutoBack: "{0}秒後自動返回",
  LB_Blend: "混合",
  LB_CancelPointPraiseSuccess: "取消點贊成功",
  LB_Cert_BeginLearn: "開始學習",
  LB_Cert_Certificate: "證書",
  LB_Cert_Finished: "已完成",
  LB_CertifiedCertificate: "可獲證書",
  LB_Charge: "收費",
  LB_ChoseCourseCatalog: "選擇你感興趣的課程目錄",
  LB_ClearRearchRecords: "清除搜索記錄",
  LB_ClickUploadPhotos: "點擊上傳照片",
  LB_Coach_EnterName: "請輸入名稱",
  LB_Coach_SelectCategory: "選擇類別",
  LB_Comment_ReplyTo: "對{0}的回覆：",
  LB_Comment_SaySomething: "說點什麼吧...",
  LB_CommentDeleted: "該評論已刪除",
  LB_ConfirmationReward: "確認打賞",
  LB_Course_Cate: "目錄",
  LB_Course_CourseDoc: "學習知識",
  LB_Course_FinalStudy: "最後學習",
  LB_Course_Goal: "課程目標",
  LB_Course_StartStudy: "開始學習",
  LB_Course_ToStudy: "進入學習",
  LB_Course_StudyProgress: "學習進度",
  LB_Course_TrainTarget: "培訓對象",
  LB_CourseDetailPlay: "暫不支持播放，請前往PC端學習該課程",
  LB_Credit_CreditsOfTheMonth: "本月學分",
  LB_Credit_CreditThisYear: "本年學分",
  LB_Credit_TotalCredit: "總學分",
  LB_CT_AvailableCertificates: "完成課程可獲得的證書",
  LB_CT_AvailableMedals: "完成課程可獲得的勳章",
  LB_CT_CourseOutline: "課程大綱",
  LB_CT_FaceType: "麵授類型",
  LB_CT_MemberNo: "暫無人員信息",
  LB_CT_NotSign: "未報名",
  LB_CurriculumFormat: "課程格式",
  LB_DepartmentRankings: "部門排行",
  LB_DetermineAssignment: "您確定要交卷嗎？",
  LB_Doc_AddDescription: "添加描述",
  LB_Doc_Comments: "評論",
  LB_Doc_Confirm_Delete: "確定要刪除嗎",
  LB_Doc_Failure: "操作失敗",
  LB_Doc_FavoriteSuccess: "收藏成功",
  LB_Doc_Mobile_Load: "加載中…",
  LB_Doc_Mobile_Screening: "篩選條件",
  LB_Doc_Source: "來源",
  LB_Doc_UploadTime: "上傳時間",
  LB_DocumentConversionWait: "文檔轉換中，請稍後...",
  LB_EnterReward: "輸入您要打賞的數值",
  LB_Error_ExamHasEnd: "考試已結束",
  LB_Error_ExamHasIng: "考試進行中",
  LB_Error_ExamHasTime: "後開始考試",
  LB_Error_SurveyNotBegin: "該問卷還未開始",
  LB_Error_SurveyNotExist: "該問卷不存在",
  LB_Error_SurveyNotView: "此問卷不許查看",
  LB_Error_SurveyOverdue: "該問卷已過期",
  LB_Error_SurveyPrepositionNot: "當前問卷有前置條件未完成",
  LB_Evaluate_Score: "分",
  LB_Exam_AllQuestion: "全部題目",
  LB_Exam_AnswerLessBanpaper: "答卷少於{0}分鐘禁止交卷",
  LB_Exam_AreYouHand: "還有{0}道試題未答,確定要交卷嗎",
  LB_Exam_BlankFilling: "填空題",
  LB_Exam_Casus: "案例題",
  LB_Exam_Cert: "證書",
  LB_Exam_CertStatus: "證書狀態",
  LB_Exam_Countdown: "倒計時",
  LB_Exam_CutScreenNum: "切屏次數",
  LB_Exam_Detail: "考試詳情",
  LB_Exam_EnterCounts: "參考次數",
  LB_Exam_ErrorQuestion: "答錯題目",
  LB_Exam_EvaluationRules: "評卷規則",
  LB_Exam_ExamNum: "考試次數",
  LB_Exam_HandPaperReturnExam: "您已成功提交了答卷,將返回我的考試",
  LB_Exam_Judgement: "判斷題",
  LB_Exam_LateControl: "遲到控製",
  LB_Exam_MultiSelect: "多選題",
  LB_Exam_NestQuestion: "下一題",
  LB_Exam_PassCondition: "及格條件",
  LB_Exam_QuestionAnalysis: "答題解析",
  LB_Exam_Radio: "單選題",
  LB_Exam_RightQuestion: "正確答案",
  LB_Exam_Secrecy: "保密",
  LB_Exam_StartExam: "開始考試",
  LB_Exam_SubPaperControl: "交卷控製",
  LB_Exam_TestTime: "考試時長",
  LB_Exam_ViewAnswerPaper: "查看答卷",
  LB_Exam_WellQuestion: "答對題目",
  LB_Examdhm: "距離考試開始還有 {0} 天 有 {1} 小時 有 {2} 分",
  LB_ExamRules: "考試規則",
  LB_Favorite_Cancel: "取消收藏",
  LB_Favorite_Score: "評分",
  LB_Favorite_SomePraise: "點贊",
  LB_Gene_Choose: "至少選擇1個！",
  LB_Group_AddTag: "添加標籤",
  LB_Group_AddTitle: "添加標題",
  LB_Group_CancelCollection: "確定取消收藏嗎？",
  LB_Group_CanNotReward: "不允許給自己文章打賞",
  LB_Group_CoverPhoto: "封麵圖片",
  LB_Group_EditorialGroup: "編輯小組",
  LB_Group_Group: "小組",
  LB_Group_GroupHead: "小組頭像",
  LB_Group_GroupMembers: "小組成員",
  LB_Group_GroupType: "小組類型",
  LB_Group_Hot: "熱門",
  LB_Group_ICreated: "我創建的",
  LB_Group_IJoined: "我加入的",
  LB_Group_JoinGroup: "加入小組",
  LB_Group_NeedAudit: "加入需要審核",
  LB_Group_NewGroup: "新建組",
  LB_Group_NotNeedAudit: "加入不需審核",
  LB_Group_Publish: "發錶",
  LB_Group_RewardArticle: "確定要打賞該文章嗎？",
  LB_Group_RewardSuccess: "打賞成功！",
  LB_Group_SearchGroup: "搜索討論組",
  LB_Group_SharingCode: "分享二維碼",
  LB_HighestScore: "最高得分：{0} 分",
  LB_HistoricalRecord: "歷史記錄",
  LB_Picture: "圖片",
  LB_Index_CT: "我的麵授",
  LB_Integral_Deduction: "扣除",
  LB_Integral_Get: "獲取",
  LB_Integral_MonthIntegral: "本月積分",
  LB_Integral_YearIntegra: "本年積分",
  LB_ISDeleteComment: "您確定要刪除此條評論？",
  LB_IsExpired: "是否過期",
  LB_LearningRankings: "學習排行榜",
  LB_lecture_Star: "星級",
  LB_lesson_TeachingTime: "授課時間",
  LB_Level_DeductingIntegral: "扣除積分",
  LB_Level_GetIntegral: "獲取積分",
  LB_Level_Score: "距升級還差{0}積分",
  LB_Live_EnterLive: "進入直播",
  LB_Live_LiveType: "直播類型",
  LB_Live_WaitLive: "請等待主講人開始直播",
  LB_Live_WhetherParticipate: "是否參與",
  LB_Mall_AllGoods: "全部商品",
  LB_Mall_Exchange: "兌換",
  LB_Mall_ExchangeRecord: "兌換記錄",
  Exchange_rules: "兌換規則",
  LB_Mall_GoToExchange: "去兌換",
  LB_Mall_ICanExchange: "我可兌換的",
  LB_Mall_Integral: "積分不夠兌換",
  LB_Mall_People: "人已兌",
  LB_Mall_StillLeft: "還剩下",
  LB_Mall_Tips: "兌換後無法撤銷,確定兌換？",
  LB_Manager_WhetherPass: "是否通過",
  LB_Map_DurationDays: "持續天數",
  LB_Map_SubDetails: "明細全部完成",
  LB_Marked: "已標記",
  LB_MedalAwarded: "可獲勳章",
  LB_Message_SelectDeleteCollection: "請選擇要刪除的收藏",
  LB_Message_SelectDeleteComment: "請選擇要刪除的評論",
  LB_Message_SelectDeleteReply: "請選擇要刪除的回覆",
  LB_Message_YouWantDeleteCollection: "確定要刪除當前選中的收藏嗎",
  LB_Message_YouWantDeleteComment: "確定要刪除當前選中的評論嗎",
  LB_Message_YouWantDeleteReply: "確定要刪除當前選中的回覆嗎",
  LB_Minutes: "{0}分鐘",
  LB_Msg_ConfirmDeleteSelect: "確定要刪除當前選中的消息嗎",
  LB_Msg_DeleteWithNoItem: "請選擇要刪除的消息內容",
  LB_Msg_Total: "共有{0}條消息",
  LB_My_Content: "我的內容",
  XB_MyManage: "我的管理",
  LB_My_Ge: "個",
  LB_My_GetCredit: "獲得 {0} 學分",
  LB_My_HelpCenter: "幫助中心",
  LB_My_History: "歷史",
  LB_My_LearningAchievements: "學習成就",
  LB_My_Mei: "枚",
  LB_My_Teaching: "授課",
  LB_My_Tiao: "條",
  LB_My_UpdateLog: "更新日誌",
  LB_MyLearningState: "我的學習狀態",
  LB_MyPhoto: "我的照片",
  LB_MyRanking: "我排在第{0}位",
  LB_Mysinup_Project: "培訓項目",
  LB_News_NewsState: "新聞狀態",
  LB_News_NumberOfVisitors: "瀏覽人數",
  LB_NoIntroduction: "暫無介紹",
  LB_NotCharge: "免費",
  LB_NotInRankings: "我還沒有上榜",
  LB_NotListData: "暫無列錶數據",
  LB_NotUploadClickUpload: "您還沒有上傳照片,{0}快來點擊上傳吧！",
  LB_NPAwardedCourse: "已有{0}人打賞該課程",
  LB_NumberWinners: "中獎人數",
  LB_NumPersonLearning: "{0}人學習",
  LB_Old_CT: "歷史麵授",
  LB_Period_TotalStudyTime: "總學習時長",
  LB_PersonScoring: "{0}人打分",
  LB_Photo: "照片",
  LB_PhotoNum: "照片數量",
  LB_PleaseQuestions: "請輸入提問的內容",
  LB_Pro_ChangeLanguage: "語言切換",
  LB_Pro_DailyAttendance: "每日簽到",
  LB_Pro_Di: "第{0}名",
  LB_Pro_Integral: "積分",
  LB_Pro_ObtainPoints: "獲得{0}積分",
  LB_Pro_RankedAgainst: "你現在的排名打敗了{0}的對手",
  LB_QA_Accepted: "被採納",
  LB_QA_AddAnswer: "追答",
  LB_QA_AddQues: "追問",
  LB_QA_AdoptCurrent: "確定採納當前回答",
  LB_QA_BeenDeleted: "該問題已刪除",
  LB_QA_ChooseRewardValue: "選擇懸賞值",
  LB_QA_DescribeDoubt: "請描述您的疑問（不能少於8個字）",
  LB_QA_DesIsNoe: "描述不能少於8個字",
  LB_QA_HotQuestion: "最熱問題",
  LB_QA_IncreaseReward: "提高懸賞，更容易吸引高手為你解答",
  LB_QA_IsResolved: "已解決",
  LB_QA_IsWonderful: "採納",
  LB_QA_IToAnswer: "我要回答",
  LB_QA_Mine: "我的",
  LB_QA_NewQuestion: "最新問題",
  LB_QA_NoReplyMsg: "暫時沒有人回覆哦，大神快來幫忙解決吧",
  LB_QA_PleaseEnter: "請輸入",
  LB_QA_Praised: "被贊",
  LB_QA_ReplyQuestion: "回覆追問",
  LB_QA_Status: "解決狀態",
  LB_QA_UnResolved: "未解決",
  LB_QA_YourCreditBalance: "您的積分餘額",
  LB_Ranking_FinishCourse: "{0}門課",
  LB_Ranking_FinishPeriod: "{0}學時",
  LB_RecentSearch: "最近搜索",
  LB_ReferenceTimes: "參考次數：{0}次",
  LB_RefreshSuccessful: "刷新成功",
  LB_Rule_Action: "動作",
  LB_Rule_Cycle: "週期",
  LB_Search_Clear: "確定要清除搜索記錄嗎?",
  LB_SelectEndTime: "選擇結束時間",
  LB_SelectStartTime: "選擇開始時間",
  LB_SendOut: "發送",
  LB_Set_EmptyPassword: "原始密碼不能為空",
  LB_Set_OriginalPassword: "原始密碼長度必須為6-20位",
  LB_Settings_Birthday: "生日",
  LB_Settings_Cellphone: "手機",
  LB_Settings_ConfirmPasswordNotNull: "確認密碼不能為空",
  LB_Settings_Email: "郵箱",
  LB_Settings_Msg_EmailFormatError: "郵箱格式不正確",
  LB_Settings_Msg_TelephoneFormatError: "座機號碼不正確",
  LB_Settings_NewPasswordNotNull: "新密碼不能為空",
  LB_Settings_NotTheSamePassword: "兩次密碼不一緻",
  LB_Settings_OriginalPasswordIncorrect: "原始密碼不正確",
  LB_Settings_PasswordLengthError: "密碼長度必須為6-20位",
  LB_Settings_Sex: "性別",
  LB_Settings_Telephone: "座機",
  LB_Shield: "屏蔽",
  LB_Shielding: "已屏蔽",
  LB_Signup_RegistrationType: "報名類型",
  LB_StartMakeUp: "開始補考",
  LB_Study_ComeOn: "同學，您還有{0}個待學習項需要完成。加油！",
  LB_Subject_DiscussionCircle: "討論圈",
  LB_SubmitEvalute: "提交評估",
  LB_SubmitStatus: "提交狀態",
  LB_Survey_DispatchMethod: "下發方式",
  LB_Survey_Participate: "參與問卷",
  LB_Survey_SubmitSuccessAndJumping: "問卷提交成功，正在返回...",
  LB_Tagged: "標記",
  LB_AnswerSheet: "答題卡",
  LB_Teacher_CreateTime: "創建時間",
  LB_Teacher_HavaPerusal: "已批閱",
  LB_Teacher_InteriorTeacher: "內部講師",
  LB_Teacher_NoAnyServiceExperience: "暫無任何服務經歷",
  LB_Teacher_NoAnyWorkExperience: "暫無任何工作經歷",
  LB_Teacher_SubSuccess: "提交成功",
  LB_Teacher_TeacherType: "講師類型",
  LB_Teacher_WithoutTeacher: "外部講師",
  LB_TotalData: "共有{0}條數據",
  LB_Track_LastPageAlert: "親，已經到最後了",
  LB_TrainingDetails: "培訓詳情",
  LB_TrainingProgram: "培訓大綱",
  LB_UnlimitedTimes: "不限次數",
  LB_UploadingAttachments: "上傳附件",
  LB_WinningResult: "中獎結果",
  M_Ranking_Integral: "獲得積分",
  MN_Course_MHome: "微課中心",
  MN_DocumentCenter_MHone: "知識中心",
  MN_Exercise_MHone: "模擬練習",
  MN_Group_MHone: "討論組",
  MN_IntegralMall_MHome: "積分商城",
  MN_KC_MHome: "答題競賽",
  online_exam: "在線考試",
  MN_LearningPaths_MHome: "學習路徑圖",
  MN_MyCourse_MHome: "我的課程",
  MN_MyWebCast_OldLive: "往期直播",
  MN_MyWebCast_PCStudyCenter: "我的直播",
  MN_News_LeftMenu: "新聞資訊",
  MN_OfflineTraining_MHome: "麵授培訓",
  MN_QA_MHome: "問答",
  MN_Subject_MHome: "專題培訓",
  MN_Survey_MHome: "問卷",
  MN_TrainingEnrollment_MHome: "培訓報名",
  MN_TrainingProject_MHome: "培訓項目",
  MN_TrainingProject_Detail: "項目詳情",
  MN_Exam_Detail: "考試詳情",
  Pub_AlreadyReceive: "已領取",
  Pub_AlreadyRegistered: "已報名",
  Pub_Article: "文章",
  Pub_Authorize: "授權",
  Pub_Portal_Website: "門戶網站",
  Pub_Btn_AlreadySign: "已簽到",
  Pub_Btn_Edit: "編輯",
  Pub_Btn_Submit: "提交",
  Pub_Close: "關閉",
  Pub_Completed: "已完成",
  Pub_CT_AssembleForTraining: "集訓",
  Pub_CT_SingleDoor: "單門",
  Pub_Data: "知識",
  Pub_Day: "天",
  Pub_Document: "文檔",
  Pub_Elective: "選修",
  Pub_Essence: "精華",
  Pub_Exam_NoExam: "暫不可考試",
  Pub_Exam_SubmitExamIsTimeOver: "考試時間已結束，正在提交試卷...",
  Pub_Female: "女",
  Pub_Hour: "小時",
  Pub_Join: "已參加",
  Pub_Lab_Administrators: "管理員",
  Pub_Lab_AnonymousSubmission: "匿名提交",
  Pub_Lab_Collect: "收藏",
  Pub_Lab_Intro: "簡介",
  Pub_Lab_NoIntro: "暫無簡介",
  Pub_Lab_CreateTime: "創建時間",
  Pub_Lab_DirectoryName: "目錄名稱",
  Pub_Lab_DocType: "知識類型",
  Pub_Lab_ExamSource: "考試來源",
  Pub_Lab_ExamStatus: "考試狀態",
  Pub_Lab_ExamTime: "考試時間",
  Pub_Lab_AnonymousReview: '匿名批閱',
  Pub_Lab_Review_time: '批閱時間',
  Pub_Lab_FinishStatus: "完成狀態",
  Pub_Lab_Group: "討論組",
  Pub_Lab_HomeworkSource: "作業來源",
  Pub_Lab_IsExpired: "是否過期",
  Pub_Lab_MarkingStatus: "批閱狀態",
  Pub_Lab_NoData: "暫無數據",
  Pub_Lab_NoRank: "暫無更多排名",
  Pub_Lab_PendingAudit: "待審核",
  Pub_Lab_PleaseEnterSearchKeywords: "請輸入搜索關鍵詞",
  Pub_Lab_Post: "崗位",
  Pub_Lab_ProjectStatus: "項目狀態",
  Pub_Lab_ProjectTime: "項目時間",
  Pub_Lab_PublishedIn: "發錶於",
  Pub_Lab_ReplyMe: "回覆我的",
  Pub_Lab_ReplySuccess: "回覆成功",
  Pub_Lab_StudyType: "學習類型",
  Pub_Lab_SurveyStatus: "問卷狀態",
  Pub_Lab_ThroughCondition: "通過條件",
  Pub_Lab_Top: "置頂",
  Pub_Lab_Topic: "話題",
  Pub_Lab_UploadTime: "上傳時間",
  Pub_Lab_UserAudit: "人員審核",
  Pub_Lab_UserName: "姓名",
  Pub_LP: "學習地圖",
  Pub_Man: "男",
  Pub_Minutes: "分",
  Pub_Msg_ActivityNoOpen: "當前活動未開啟",
  Pub_Msg_DJoinG: "確定要加入該小組？",
  Pub_Msg_DOutG: "確定要退出小組？",
  Pub_Msg_DUAuditF: "確定該用戶審核不通過？",
  Pub_Msg_DUAuditS: "確定該用戶審核成功？",
  Pub_Msg_NoGetHonor: "您還未獲得此勳章",
  Pub_Msg_NotGet: "未獲得",
  Pub_Msg_NoRepeatSubmit: "不可重複提交",
  Pub_Msg_SaveFail: "保存失敗!",
  Pub_Msg_SaveSuccess: "保存成功!",
  Pub_Msg_SubmitFail: "提交失敗!",
  Pub_Msg_SuccessComment: "評論成功	",
  Pub_Msg_TitleNotNull: "標題不能為空",
  Pub_Normal: "正常",
  Pub_Notexpired: "未過期",
  Pub_NotObtainedCertificate: "您還未獲得此證書",
  Pub_Notpassed: "未通過",
  Pub_NotRegistered: "未報名",
  Pub_NotSubmitted: "未提交",
  Pub_Open: "公開",
  Pub_Ord_AgreeNum: "被贊數",
  Pub_Ord_Answer: "回答數",
  Pub_Ord_CompleteProgress: "完成進度",
  Pub_Ord_Progress: "完成進度",
  Pub_Ord_Recommend: "推薦",
  Pub_Ord_ReplayTime: "回覆時間",
  Pub_Ord_ViewNum: "查看數",
  Pub_Passed: "已通過",
  Pub_PathMap: "路徑圖",
  Pub_Rebut: "被駁回",
  Pub_RecommendedCourse: "推薦課程",
  Pub_Required: "必修",
  Pub_Submitted: "已提交",
  Pub_Tab_AlreadyRegistered: "已報名",
  Pub_Tab_Assessment: "評估錶",
  Pub_Tab_Attended: "參加了",
  Pub_Tab_Checkpoint: "關卡",
  Pub_Tab_CommentNum: "評論",
  Pub_Tab_Credit: "學分",
  Pub_Tab_CT: "麵授",
  Pub_Tab_Detail: "詳情",
  Pub_Tab_Enrollment: "報名人數",
  Pub_Tab_EveryDay: "每天",
  Pub_Tab_GetIntegralByday: "連續簽到 {0} 天 額外獎勵 {1} 積分",
  Pub_Tab_GoSign: "去報名",
  Pub_Tab_Knowledge: "知識",
  Pub_Tab_LastStudy: "上次學習",
  Pub_Tab_NotStart: "未開始",
  Pub_Tab_Progress: "進度",
  Pub_Tab_ReplyTo: "回覆於",
  Pub_Tab_ReRegistration: "重新報名",
  Pub_Tab_Score: "得分",
  Pub_Tab_Time: "時間",
  Pub_Tab_TrainingTime: "培訓時間",
  Pub_Tab_PlannedTrainingTime: "計畫培訓時間",
  Pub_TaskOrCT: "項目/麵授",
  Pub_Uncommitted: "未參加",
  Pub_Uncompleted: "未完成",
  Pub_Video: "視頻",
  Pub_WithoutApproval: "未批閱",
  Sys_Exam: "在線考試",
  Sys_Homework: "實操作業",
  Sys_WatchLive: "觀看 {0} 分鐘",
  Sys_WebCast: "直播",
  AI_Practice: "AI對練",
  AI_Practice_tips: "請前往移動端參與AI對練",
  XB_AccordingAcademicScore: "按獲得學分數",
  XB_Add_Content: "附加內容",
  XB_AddElectives: "添加選修",
  XB_AddingTaskFailed: "新增任務失敗",
  XB_Advance: "嚮您推薦",
  XB_AreYouFinished: "確定完成嗎?",
  XB_AreYouSureCancelTopping: "確定取消置頂嗎?",
  XB_AreYouSureDeleteComment: "確定刪除此評論嗎?",
  XB_AreYouSureDeleteGroup: "確定刪除當前討論組嗎?",
  XB_AreYouSureDeleteReply: "確定刪除此回覆嗎？",
  XB_AreYouSureDeleteTopic: "確定刪除當前話題嗎?",
  XB_AreYouSureEssenceStatus: "確定取消精華狀態嗎?",
  XB_AreYouSureQuitGroup: "確定退出當前討論組嗎?",
  XB_AreYouSureSetElite: "確定設為精華狀態嗎?",
  XB_AreYouSureTopStatus: "確定設為置頂狀態嗎?",
  XB_AuthorizationTime: "授權時間",
  XB_Back_Home: "返回主頁",
  XB_Basic_Info: "抄送內容已包括培訓的基本信息,此為附加內容",
  XB_BeenExitedGroup: "已退出當前組",
  XB_BeingPublished: "發錶中...",
  XB_BindAccount: "綁定賬號",
  XB_BindingFailed: "綁定失敗",
  XB_BindingSucceeded: "綁定成功",
  XB_Bonus_Points: "打賞的積分不可為空",
  XB_ByNumberCompletedResources: "按完成資源數",
  XB_Cc: "抄送",
  XB_Check_Staff: "簽到人員",
  XB_Choose_Content: "請選擇抄送內容",
  XB_Choose_Method: "請選擇抄送方式",
  XB_ChooseOperation: "選擇操作",
  XB_Class_Address: "上課地址",
  XB_CompanyRanking: "公司排行",
  XB_Completed_Course: "恭喜您完成課程",
  XB_Completed_First: "必須先完成上一章節!",
  XB_ConfigurationExam: "配置考試",
  XB_ConfirmPassword: "確認密碼",
  XB_CongratulationsGetCert: "恭喜您獲取【{0}】證書",
  XB_CongratulationsGetMedal: "恭喜您獲取【{0}】勳章",
  XB_Copy_Send_Info: "抄送信息",
  XB_Copy_Send_Method: "抄送方式",
  XB_CorporateIdentity: "企業標識",
  XB_CourseInformation: "課程信息",
  XB_CustomsClearance: "通關",
  XB_DesignatedLearners: "指定學習人員",
  XB_DetailsNumber: "明細數",
  XB_Distinguish: "長按識別二維碼",
  XB_Duration: "時長",
  XB_DynamicQRCode: "動態二維碼",
  XB_End_Time: "結束時間已過",
  XB_EndTimeCannotEarlierThanStartTime: "結束時間不能小於開始時間",
  XB_Enter_Class_Address: "輸入上課地址",
  XB_Enter_Face_Name: "輸入麵授名稱",
  XB_EnterpriseIdNotEmpty: "企業標識不能為空",
  XB_EssenceBeenCanceled: "已取消精華狀態",
  XB_Exam_AnswerCannotBeViewed: "不可查看答卷",
  XB_Exam_AreYouSureSaveAnswerInfo: "確定要保存當前答題信息嗎？",
  XB_Exam_SaveAnswerInfo: "已為您保存答卷信息",
  XB_ExaminationOpeningConditions: "開考條件",
  XB_ExitFailed: "退出失敗",
  XB_Face_To_Face: "麵授基本信息",
  XB_Famous_Teachers: "名師推薦",
  XB_FirstCompleteFrontResource: "請先完成前置資源",
  XB_Five_Star_Class: "五星好課",
  XB_FixedQRCode: "固定二維碼",
  XB_Get_Integral: "輸入可獲得積分，默認為0",
  XB_GetFollowingHonors: "通過該學習地圖可獲得以下榮譽：",
  XB_Give_Reward: "請打賞",
  XB_GiveUpContinueAnswerQuestions: "確定要放棄繼續答題嗎？",
  XB_Good_Review: "給個好評鼓勵下吧",
  XB_Growth_Sharing: "成長分享",
  XB_Heat: "熱度",
  XB_Hello: "你好",
  XB_Time: "時",
  XB_HowManyDaysExpire: "還有{0}天到期",
  XB_HowManyPeople: "當前上榜{0}人",
  XB_Insufficient_Points: "積分不足",
  XB_Interaction_Page: "正在返回至互動主頁",
  XB_InteractiveConfiguration: "互動配置",
  XB_Invitation: "邀請函",
  XB_Invited: "邀請你來學習",
  XB_Is_Required: "為必填項",
  XB_ItsCourse: "TA的課程",
  XB_Join_Study: "加入學習",
  XB_Join_Study_First: "請先加入學習",
  XB_Join_Successfully: "加入成功",
  XB_Joined: "已加入",
  XB_JoinFailed: "加入失敗",
  XB_Learn: "學習了",
  XB_Learn_Together: "共同學習",
  XB_Lecturer: "主講老師",
  XB_Lecturer_Details: "講師詳情",
  XB_LoginInProgress: "登錄中...",
  XB_Mail: "郵件",
  XB_Mall_BeenRedeemed: "已兌完",
  XB_Mall_ExchangeFailed: "兌換失敗！",
  XB_Manuscripts: "文稿",
  XB_MobileCannotDownload: "移動端無法下載，請前往PC端",
  XB_MobileCTNotEdit: "在Mobile端，集訓目前不支持編輯",
  XB_MobileOnlyForDisplay: "PC端配置,Mobile僅作展示",
  XB_Modify_Job: "修改作業",
  XB_Month: "本月",
  XB_MyMotto: "我的座右銘",
  XB_Nail: "釘釘",
  XB_NewFailed: "新增失敗",
  XB_NewPassword: "新密碼",
  XB_NoExamYet: "暫無考試",
  XB_NoHistoricalRecord: "暫無歷史記錄",
  XB_Not_Converted:
    "當前章節係統正在轉換，請耐心等待，自動轉換完成後方可學習。",
  XB_Not_Drag: "不能拖動到未觀看的進度",
  XB_NotCollected: "您還未領取",
  XB_NotFilledIn: "未填寫",
  XB_NoUpdateLog: "暫無更新日誌",
  XB_NumberLevels: "關卡數",
  XB_NumberStages: "階段數",
  XB_Obtain_certificates: "您已獲得以下證書",
  XB_Obtain_medals: "您已獲得以下勳章",
  XB_Open_To_Use: "開啟方可使用",
  XB_OptionalCourses: "可選課程",
  XB_OriginalPassword: "原始密碼",
  XB_OtherConfigurations: "其它配置",
  XB_Parameter_Error: "參數錯誤",
  XB_PassExamGetFollowCert: "通過考試可獲得以下證書:",
  XB_PassExamGetFollowMedals: "通過考試可獲得以下勳章:",
  XB_Pattern: "花樣",
  XB_Person_Learning: "{0}人在學",
  XB_PleaseChooseEndTime: "請選擇結束時間",
  XB_PleaseChooseLecturer: "請選擇主講老師",
  XB_PleaseChooseStartTime: "請選擇開始時間",
  XB_PleaseEnterClassAddress: "請輸入上課地址",
  XB_PleaseEnterLessonHour: "請輸入正確的課時",
  XB_Popular_Recommendation: "爆款推薦",
  XB_Press_Send: "長按上方圖片，保存或發送給朋友",
  XB_PublicationFailed: "發錶失敗",
  XB_PublishedSuccessfully: "發錶成功",
  XB_RankingList: "排行榜",
  XB_ReadIt: "閱讀了",
  XB_RegistrationControl: "報名控製",
  XB_RequiresCompletionFrontLevel: "解鎖需完成前置關卡",
  XB_Return: "您已成功提交作業,正在返回上一頁",
  XB_Returning: "正在返回",
  XB_Reward_Failed: "打賞失敗",
  XB_Saving: "保存中...",
  XB_Select_Send_Method: "選擇抄送方式",
  XB_SelectCompletionCriteria: "選擇完成條件",
  XB_SelectedCourses: "已選課程",
  XB_SelectQRCodeType: "選擇二維碼類型",
  XB_Send_Not_Empty: "抄送人不能為空",
  XB_Send_Success: "發送成功",
  XB_SendTopic: "發話題",
  XB_SetupSucceeded: "設置成功",
  XB_Share: "分享",
  XB_Share_Course: "分享給你",
  XB_SignUpSuccessfully: "報名成功",
  ExistingProjectPermissionsNoRegistrationRequired: "已有項目的學習權限，無需參與報名！",
  XB_Singing_Off: "簽退人員",
  XB_SorryNoMatchingAccount:
    "抱歉，未能在學習平臺中找到匹配的賬號,請與管理員聯繫！",
  XB_StageNotOpen: "階段未開啟",
  XB_Star_Score: "輕觸星星評分",
  XB_Start_Quick: "快速開始學習",
  XB_StartLessonReminder: "開課提醒",
  XB_Strive: "努力",
  XB_Submit_Success: "提交成功，正在返回...",
  XB_Success_Back: "您已成功提交評估,正在返回上一頁",
  XB_SummaryFirst: "請先填寫總結內容",
  XB_SupportedFileFormats: "支持的文件格式：",
  XB_Teaching_Hours: "授課課時",
  XB_TemporarilyUnableToLearn: "暫不可學",
  XB_Test_Scores: "考試分數",
  XB_TheVersionNumber: "版本號",
  XB_Think_Good: "覺得這門課程不錯",
  XB_Today: "今日",
  XB_ToppingBeenCancelled: "已取消置頂狀態",
  XB_UnbindingFailed: "解綁失敗",
  XB_UnbindingSucceeded: "解綁成功",
  XB_UploadingDocument: "正在上傳文件...",
  XB_UserNameNotEmpty: "用戶名不能為空",
  XB_VerifyYourIdentity: "正在驗證您的身份，請稍等...",
  XB_WatchedIt: "觀看了",
  XB_WeChart: "微信",
  XB_Week: "本週",
  XB_YouNotBound: "您還沒有綁定學習平臺賬號，趕緊綁定，開始微信學習吧！",
  LB_Account_BindingAccount: "綁定帳號",
  XB_Course_CompletionConditions: "課程完成條件",
  XB_Finish: "完成",
  XB_Test: "測試",
  XB_Text: "文本",
  XB_Course_ChapterSituation: "課程章節情況",
  XB_Studying: "學習中",
  XB_FinishedLearning: "已學完",
  XB_DidNotLearn: "未學習",
  XB_StartDate: "開始日期",
  XB_EndDate: "截止日期",
  XB_ViewRules: "查看規則",
  XB_NoMoreExamRecordsSo: "暫無更多考試記錄，趕快參加考試吧",
  XB_ExamDate: "考試日期",
  XB_Min: "分鐘",
  XB_JustMomentPendingApproval: "請稍等，試卷等待批閱中。",
  XB_CongratulationsPassedExam: "恭喜您，通過了本次考試！",
  XB_UnfortunatelyNoPassExam: "很遺憾，您沒有通過考試。",
  XB_ExaminationHistory: "考試歷史記錄",
  XB_Mark: "分數",
  XB_NoMoreExamRecords: "暫無更多考試記錄",
  XB_ThisExamRules: "本場考試規則",
  XB_ExamNeedingAttention: "考試人員應註意如下事項",
  XB_PassExamToGet: "通過考試可獲得",
  XB_Waiting: "等待中",
  XB_CurrentIntegralAre: "您當前積分為",
  XB_AdminSideslipEdit: "管理員側滑可編輯組員",
  XB_MyRanking: "我的排行",
  XB_DefeatOpponent: "擊敗對手",
  XB_SpecifyLearningContent: "指定學習內容",
  XB_CertificatesCanObtainedTask: "完成任務可獲得的證書",
  XB_MedalCanObtainedTask: "完成任務可獲得的勳章",
  XB_UnderWay: "進行中",
  XB_OpeningHours: "開放時間",
  XB_ExerciseDuration: "練習時長",
  XB_RemainingDuration: "剩餘時長",
  XB_CorrectNumber: "正確數",
  XB_TotalNumberQuestions: "總題數",
  XB_StudentMedal: "學員勳章",
  XB_CertificateHonor: "榮譽證書",
  XB_FacialIdentification: "人臉識別",
  XB_HasSampled: "已採樣",
  XB_Resampling: "重新採樣",
  XB_Unthread: "不通過",
  XB_WantTakeSamples: "我要採樣",
  XB_SignInWithPrizes: "簽到有獎",
  XB_SignInTodayGet: "今日簽到獲得",
  XB_QuickRegistration: "快速註冊",
  XB_AnalysisQuestions: "試題解析",
  XB_GradeDifficulty: "難度等級",
  XB_EndExercise: "結束練習",
  XB_Collected: "已收藏",
  XB_CorrectRateTime: "本次正確率",
  XB_MyWrongQuestion: "我的錯題",
  XB_NumberExercises: "練習數",
  XB_WrongExercises: "錯題練習",
  XB_BackExercises: "返回練習",
  XB_ExerciseTimeExpired: "當前練習參與時長已到，無法再次參與",
  XB_CurrentErrorEmpty: "當前錯題為空",
  XB_SorryNoExercises: "對不起，您沒有可參與的練習",
  XB_SorryNoExam: "對不起，您沒有可參與的考試",
  XB_InitExercisesFailed: "初始化練習記錄失敗",
  XB_ContinueLastAnswer: "是否繼續上次的答題？",
  XB_MockExamFailed: "您當前模擬考試未通過，得分",
  XB_MockExamPassed: "您當前模擬考試已通過，得分",
  XB_MyQuestionBank: "我的題庫",
  XB_ExamRecord: "考試記錄",
  XB_MockExam: "模擬考試",
  XB_SwitchQuestionBank: "切換題庫",
  XB_SequentialPractice: "順序練習",
  XB_SequenceExerciseTopic: "按照指定順序練習題目",
  XB_RandomPractice: "隨機練習",
  XB_RandomExerciseTopic: "隨機規則練習題目",
  XB_DifficultExercises: "難題練習",
  XB_CarefullyScreenDifficultProblems: "精心篩選該題庫中所有選難題",
  XB_MyCollection: "我的收藏",
  XB_CollectExercises: "在模擬練習收藏重點題目",
  XB_WrongQuestionSet: "錯題集",
  XB_SummarizeExerciseMistakes: "總結練習過程中所有錯題",
  XB_SwitchExercise: "切換習題",
  XB_SelectValidExercise: "請選擇一個有效的練習",
  XB_NoExercises: "暫無練習題",
  XB_NoLeaderboard: "暫無排行榜",
  XB_NoExamRecord: "暫無考試紀錄",
  XB_NoExamFrequency: "您的考試次數已用盡",
  XB_CurrentNotRanking: "當前排名沒有上榜，繼續加油哦~",
  XB_NoScoreRanking: "您還沒有考試,沒有分數排名喲~",
  XB_CongratulationsRight: "恭喜您,答對了",
  XB_GotOnlyPartRight: "您隻答對了一部分",
  XB_UnfortunatelyAnswerWrong: "很遺憾,您答錯了",
  XB_Cockpit: "駕駛艙",
  XB_Files: "檔案",
  XB_ViewPlayback: "查看回放",
  XB_ServiceAgreement: "服務協議",
  XB_PrivacyPolicy: "隱私政策",
  XB_Interactions: "{0}個互動",
  XB_LogOut: "退出登錄",
  login: {
    account: '賬號',
    account_p: '請輸入您的手機號/郵箱/賬號',
    account_p2: '請輸入賬號！',
    password: '密碼',
    password_p: '請輸入密碼',
    captcha: '驗證碼',
    captcha_p: '請輸入驗證碼',
    register: '註冊賬號',
    forgot: '忘記密碼',
    scan_code_login: '掃碼登錄',
    account_login: '賬號登錄',
    use_palform_scan: '請使用{0}掃描二維碼登錄',
    wx: '微信',
    ding: '釘釘',
    your_q_r_code_is_invalid: '您的二維碼已失效',
    please_click_the_refresh_button_below: '請點擊下方刷新按鈕',
    refresh: '刷新',
    failed_to_create_q_r_code: '創建二維碼失敗',
    login_failed: '登錄失敗',
    //
    mobile_or_email: '手機號或郵箱',
    mobile_or_email_p: '請輸入手機號或郵箱',
    mobile_or_email_err: '手機號或郵箱不正確',
    code: '驗證碼',
    code_p: '請輸入驗證碼',
    new_password: '新密碼',
    new_password_p: '請輸入新密碼',
    confirm_password: '確認密碼',
    confirm_password_p: '請輸入確認密碼',
    not_match: '兩次密碼不一緻',
    to_login: '去登錄',
    successfully_retrieved_the_password: '成功找回密碼！',
    success_tip: '您的密碼已經重置，快去登錄平臺試試吧。',
    get_code: '獲取驗證碼',
    failed_get_code: '獲取驗證碼失敗',
    //
    account_registration: '賬號註冊',
    enterprise_registration: '單位註冊',
    enterprise: '單位',
    enterprise_p: '請選擇單位',
    enterprise_tip: '如果單位列錶中找不到所在單位，請聯繫單位負責人 | 先在平臺進行',
    account_regist: '請輸入至少三個字符',
    name: '姓名',
    name_p: '請輸入姓名',
    address: '地址',
    area: '區縣',
    area_p: '請選擇區縣',
    mobile: '手機號',
    mobile_p: '請輸入手機號',
    mobile_err: '手機號不正確',
    email: '郵箱',
    email_p: '請輸入郵箱',
    register_btn: '註冊',
    register_success_title: '恭喜您，註冊成功！',
    register_success_tip: '您的賬號已經註冊成功，快去登錄平臺試試吧。',
    enterprise_name: '單位名稱',
    enterprise_name_p: '請輸入單位名稱',
    agree: '同意',
    submit: '同意並註冊',
    termsOfService: '《Traingo服務條款》',
    legalNotice: '《法律聲明和隱私政策》',
    readAndTick: '請閱讀並勾選《Traingo服務條款》《法律聲明和隱私政策》',
    emial_format_err: '郵箱格式不正確',
    tel_format_err: '座機格式不正確',
    password_len: '密碼不能少於8位',
    password_strong: '密碼不能少於8位且需要包含大寫字母、小寫字母、數字和符號中的任意三項',
    please_enter_chinese: '請輸入中文',
    please_enter_the_value: '請輸入數值',
    personal_information: '個人信息',
    birthday: '出生日期',
    birthday_p: '請選擇出生日期',
    landline: '座機',
    landline_p: '請輸入座機',
    save_submit: '保存並提交',
    old_password: '原密碼',
    ok_to_modify: '確定修改',
    avatar: '頭像',
    upload_avatar: '上傳頭像',
    logout_tip: '確定要退出登錄嗎？',
    password_rules: '密碼規則',
    to_improve_account_security: '為提高賬號安全，',
    original_password_must_be_changed: '必須修改原始密碼。',
    first_login_to_account: '第一次登錄賬號',
    login_password_expired: '登錄密碼過期',
    new_login_password: '新的登錄密碼',
    confirm_new_password: '確認新的密碼',
    please_enter_new_password: '請輸入新密碼',
    please_enter_confirmation_password: '請輸入確認密碼',
    two_passwords_are_inconsistent: '兩次密碼不一緻',
    strong_password_rules_0: '* 為保障您的賬號安全，請根據以下規則設置密碼，密碼有效期為{number}天。密碼規則為：',
    strong_password_rules_1: "1、密碼至少由8位及以上大寫字母、小寫字母、數字與特殊符號(不可包含& % = + 這四個符號)等4類中的3類混合、隨機組成，盡量不要以姓名、電話號碼以及出生日期等作為密碼或者密碼的組成部分；",
    strong_password_rules_2: "2、密碼應與用戶名無相關性，密碼中不要包含用戶名的完整字符串、大小寫變形或者形似變換的字符串，如“用戶名:密碼”不能為admin:admin、admin:admin2023、admin:Admin、admin:{'@'}dmin等；",
    strong_password_rules_3: "3、密碼應避免3位以上(含3位)鍵盤連續排序字符，如qwe(字母第1行前三個)、asd(字母第2行前三個)、qaz(字母第1列)、QAZ(字母第1列大寫)、1qa(鍵盤第1列)等;",
    strong_password_rules_4: "4、密碼中不能出現3位以上(含3位)連續字母、數字、特殊符號，如ABC、Abc、123、!{'@'}#等;",
    strong_password_rules_5: "5、密碼中不能出現3位以上(含3位)重複字母、數字、特殊符號，如AAA.Aaa、111、###等。",
    strong_password_rules_tips_1: '密碼不能少於8位且需要包含大寫字母、小寫字母、數字與特殊符號中的任意三項',
    strong_password_rules_tips_2: '密碼應與用戶名無相關性',
    strong_password_rules_tips_3: '密碼不能包含& % = + 這四個符號',
    strong_password_rules_tips_4: '密碼不能包含3個及以上鍵盤連續字符或連續3位及以上相同字母或數字',
  },
  next: '下一步',
  pre: '上一步',
  and: '與',
  mine: {
    month: '月',
    lv: '等級值',
    chatGPT: '飛訓聊天機器人',
    live_course: '直播課程',
    my_lecture: '我的授課',
    my_certificate: '我的證書',
    exam_review: '試卷批閱',
    review_completed: '批閱完成',
    my_order: '我的訂單',
    activation_key_management: '激活碼管理',
    unit_management: '單位管理',
    my_credits: '我的學分',
    my_school_hours: '我的學時',
    my_integral: '我的積分',
    my_likes: '我的點贊',
    current_version: '當前版本',
    already_the_latest_version: '已經是最新版本！',
    clear_the_cache: '確定要清除緩存嗎？',
    clear_immediately: '立即清除',
    clear_success: '清除成功',
    change_password_tip: '第一次登錄賬號 | 登錄密碼過期',
    change_password_tip_title: '為提高賬號安全，{0}必須修改原始密碼。',
    camera_permissions: '請前往設置開啟相機權限',
    successfully_modified: '修改成功！',
    learning_center: '學習中心',
    my_face: '我的麵授',
    my_exam: '我的考試',
    my_activity: '我的活動',
    my_questionnaire: '我的問卷',
    learning_path: '學習軌跡',
    interaction_center: '互動中心',
    homework_approval: '作業批閱',
    help_manage: '幫帶',
    self_help: '員工自助',
    lecturer_apply: '講師申請',
    demand_report: '需求上報',
    educational: '教務管理',
    complete_online_course: '完成在線課程數',
    month_learn_hours: '在線課程+考試用時+培訓中的知識',
    month_study_time: '本月學習時長',
    month_study_time_rank: '本月學習時長排行',
    no_task: '暫無任務派發，去學習其他任務吧～',
    set_off: '出發',
    sure_delete_comment: '確定要刪除該條評論嗎',
    sure_delete_reply: '確定要刪除該條回覆嗎',
    list_date: '排行日期',
    list_together: '榜單合計',
    people: '人',
    company_rank: '公司排行榜',
    department_rank: '部門排行榜',
    see_more_list: '查看更多排行榜',
    message_center: '消息中心',
    total_of: '共有 | 條',
    delete_msg_tip: '確定要刪除當前選中的消息嗎？',
    please_choose_to_delete_message_content: '請選擇要刪除的消息內容',
    cumulative_signin: '本月纍計簽到{0}天',
    sign_in: '簽到中',
    current_level: '當前等級',
    tier_points: '等級積分值',
    level_comparison_table: '等級對照錶',
    level_top: '您的等級已經登頂，難道是傳說中的大神？接受眾人膜拜吧',
    get_another_nth_points_to_upgrade_to: '再獲取{0}點積分值升級到Lv{1}',
    score_rule_tip: '進行以下動作會獲得積分獎勵，在一個動作週期內你最多得到的獎勵次數有限製。',
    learning_archive: '學習檔案',
    my_project: '我的項目',
    project_type: '項目類型',
  },
  Version: '版本',
  daily_exam: '每日一練',
  cm_subject: '課程專題',
  cm_package: '培訓包',
  training_package_details: '培訓包詳情',
  training_package_purchase: '培訓包購買',
  cm_newstaff: '新人培訓',
  newstaff_details: '新人培訓詳情',
  learning_details: '學習詳情',
  news_list: '新聞列錶',
  news_details: '新聞詳情',
  face_to_face_details: '麵授詳情',
  questionnaire_details: '問卷詳情',
  questionnaire_survey: '問卷調查',
  task_supervision: '任務督導',
  cm_activity: '活動',
  demand_collection: '需求徵集',
  cm_project: '培訓實施',
  face_lecturer: '學員麵授',
  lecturer_face: '講師麵授',
  attendance_face: '考勤麵授',
  exam_approval: '考試批閱',
  cm_bbs: '學習社區',
  circle_members: '圈子成員',
  make_up_exam: '補考',
  chat: {
    mode: '文本模式 | 畫圖模式',
    input_p: '您可以直接在這裡輸入您的問題哦～',
    load_text: '正在獲取消息 | 消息獲取成功',
    answer_tip: '您好，很高興為您服務！遇到什麼問題您可以直接在這裡輸入哦~',
    send_tip: '請輸入有效的內容 | 響應中',
  },
  face_state: '麵授狀態',
  from_start: '距開始',
  from_end: '距結束',
  on_line: '線上',
  offline: '線下',
  issued_online: '線上頒發',
  Valid_until: '有效期至',
  permanent: '永久',
  Review_times: '批閱次數',
  source_task: '來源任務',
  CM_Personal: '個人',
  CM_Unit: '單位',
  CM_OrderId: '訂單號',
  XB_AreYouSureDeleteOrder: "確定刪除訂單嗎?",
  validity_period: '有效期',
  currency_symbol: '¥',
  order: {
    month: '個月',
    order_type: '訂單類型',
    order_state: '訂單狀態',
    order_stats_option: '已支付 | 未支付',
    order_id: '訂單編號',
    wechat_id: '微信交易號',
    pay_time: '支付時間',
    total: '總金額',
    View_activation_code: '查看激活碼',
    Activation_code_list: '激活碼列錶',
    activation_code: '激活碼',
    user: '使用人',
    usage_time: '使用時間',
    price: '單價',
    activation_option: '已激活/未激活',
    use_state_option: '已使用 | 未使用',
    purchase_time: '購買時間',
    purchase_quantity: '購買數量',
    purchasing_price: '購買金額',
    please_enter_package_name: '請輸入培訓包名稱',
    table: {
      serial: '序號',
      package_name: '培訓包名稱',
      orderId: '訂單號',
      buy_account_num: '購買賬號數',
      time: '時間',
      remaining_code: '剩餘激活碼',
      operate: '操作',
      activation_code: '激活碼',
      name: '姓名',
      account: '賬號',
      tel: '手機號',
      use_time: '使用時間',
    },
  },
  CM_Year: '年',
  CM_quantity: '數量',
  enterprise: {
    personnel_infor: '人員信息 | 人員信息列錶',
    personnel_learning: '人員學習情況 | 人員學習情況列錶',
    table: {
      avatar: '頭像',
      account: '賬號 | 請輸入賬號',
      real_name: '姓名 | 請輸入姓名',
      unit: '單位',
      state: '狀態 | 請選擇狀態',
      package: '培訓包 | 請輸入培訓包名稱',
      study_time: '學習時間',
      whether_to_pass: '是否通過',
      learning_progress: '學習進度',
      study_completed: '學習完成 | 請選擇完成狀態',
      create_time: '創建時間',
    },
    info_state: '正常 | 停用',
    pass_state: '已通過 | 未通過',
    learn_state: '完成 | 進行中 | 逾期未完成',
    complete_time: '完成時間',
    recent_study: '最近學習',
    have_completed_all: '恭喜您任務已經全部學完！',
    have_not_started_learn: "您還沒有開始學習！",
    tip: '您已完成 | 個任務，還剩 | 個學習任務！',
  },
  credits: {
    total_credits: '總學分',
    year_credits: '本年獲得學分',
    month_credits: '本月獲得學分',
    adjust_credits: '調整學分',
    remark: '備註',
    Remark_text: '完成【{0}】的獎勵學分',
    online_courses: '在線課程',
    training_task: '培訓任務',
    year: '年度',
    tips: '用於記錄您完成學習獲得的獎勵學分',
    all_record: '全部學分記錄',
  },
  mytime: {
    total_hours: '總學時',
    year_hours: '本年獲得學時',
    month_hours: '本月獲得學時',
    Remark_text: '完成【{0}】的獎勵學時',
    tips: '用於記錄您完成學習獲得的獎勵學時',
    all_record: '全部學時記錄',
    table: {
      name_of_resource: '資源名稱',
      resource_type: '資源類型',
      time: '時間',
      descr: '備註說明',
    },
  },
  integral: {
    level_integral: '等級積分',
    available_integral: '可用積分',
    year_integral: '本年獲得積分',
    month_integral: '本月獲得積分',
    get_time: '獲得時間',
    remark: '備註說明',
    answer_practice: '答題練習',
    table: {
      action: '動作名稱',
      cycle: '動作週期',
      integral: '積分',
      daily_limit: '每日積分上限',
      cycle_state: '一次性 | 不限製',
      descr: '描述說明',
      bonus_points: '獎勵積分',
    },
    tips: '用於記錄您登錄、分享、評論、資源學習等產生平臺活躍度的積分獎勵',
    all_record: '全部積分記錄',
    cycle_tip: '一次性：該動作隻需要完成1次，獎勵1次。不限製：該動作可以完成多次，獎勵多次。',
  },
  like: {
    news_like: '新聞點贊',
    knowledge_like: '知識點贊',
    like_time: '點贊時間',
    news_category: '新聞分類',
    knowledge_category: '知識分類',
  },
  select_date: '選擇日期',
  study: {
    title: '我的學習任務',
    to_be_completed: '待完成',
    month: '月',
    learn_in: '{0}{1}內學完',
    title_2: '我的學習',
    title_tip: '這裡有你的學習內容和待辦事項',
    learning_tasks: '學習任務',
    no_task: '暫無任務派發，去自主學習吧',
    filter_type: '篩選類型',
    overdue_learn: '逾期可學',
    days_remaining: '剩餘{0}天',
  },
  home: {
    search_p: '請輸入關鍵內容',
    more_features: '更多功能',
    referenced: '人已參考',
    go: '進入',
    face: '麵授',
    person_study: '人學習',
    waiting: '虛偽以待',
    rank_format: {
      h: `時`,
      m: `分`,
      s: `秒`,
    },
    learned_last_time: '上次學到',
    continue_studying: '繼續學習',
    init_data: '初始化數據中，請稍等。',
    cannot_study: "問卷暫不能學習",
    resources_tip: '請先完成前麵的資源',
    not_yet_started: '活動暫未開始',
    not_yet_expired: '活動已過期',
    recommend: '推薦',
    hot_list: '熱榜',
    top: '置頂',
    infor: '資訊',
    infor_tip: '天下大事、行業資訊盡收眼底',
    konwledge_tip: '企業知識寶庫 讓你瞭解業務快人一步',
    no_hot_content: '暫無熱榜內容',
  },
  no_lecturer: '暫無講師',
  search: {
    no_search_record: '暫無搜索記錄',
    enter_keywrod: '請輸入關鍵詞！',
    history_search: '歷史搜索',
    delete_all: '全部刪除',
    hot: '熱',
    total: '共找到 | 個結果',
    noData: '抱歉，沒有搜索到相關內容～',
  },
  resource: {
    resource_center: '資源中心',
    resource_center_tip: '課程精選·講師推薦',
    featured_courses: '課程精選',
    all_courses: '全部課程',
    view_more_course: '查看更多課程內容',
    no_course: '暫無課程內容',
    knowledge_selection: '知識精選',
    all_knowledge: '全部知識',
    no_knowledge: '暫無知識內容',
    industry_big_coffee: '行業大咖',
    no_industry: '暫無行業大咖',
  },
  category: '分類',
  course: {
    learning_times: '學習次數',
    total_progress: '總進度',
    switch_video_play_mode: '切換視頻播放模式',
    audio_play: '音頻播放',
    video_play: '視頻播放',
    now_playing: '（正在播放）',
    speed: '倍速',
    no_course_permission: '您沒有該課程的學習權限',
    confirm_learning: '請確認您是否還在學習？',
    please_enter_course: '請輸入課程名稱',
    format: '格式',
    to_learn: '再學',
    lessons_learned: '課程學完',
    you_have_completed_this_section_of_study: '恭喜，您已完成本章節的學習',
    classes: '{0}節課程',
    people_score: '{0}人打分',
    course_intro: '課程介紹',
    course_catalog: '課程目錄',
    course_grade: '課程評分',
    course_comment: '課程評論',
    section: '第{0}節',
    course_not_exist: '抱歉，您訪問的課程不存在。',
    this_chapter_is_currently_playing: '當前正在播放此章節',
    the_current_chapter_is_not_converted: '當前章節未轉換完成',
    thank_you_for_your_rating: '感謝您的評分！',
    study_stop_continue: '當前學習已暫停，是否繼續學習',
    face_tip: '請將您的 | 臉部 | 放入識別框內識別',
    face_click_tip: '已識別到人臉，請點擊確定',
    evaluate: '評估',
    homework: '作業',
    go: '去評分 | 寫作業 | 去考試',
    need_tip: '課程章節未學完，暫不可考試',
    looking_forward_to_your_positive_comments: '期待您的好評',
    your_encouragement_can_make_us_do_better: '您的鼓勵能讓我們做得更好',
    online_course: '在線課程',
    course_recommendations: '課程建議',
    course_recommendations_placeholder: '請輸入您的建議,不超過200字',
    CoursePracticeRecord: '實操記錄',
    ExperimentalResults: '實驗成績',
    ScoreDetails: '成績明細',
    ExperimentalStartTime: '實驗開始時間',
    EndTimeOfExperiment: '實驗結束時間',
    DidYouAnswerCorrectly: '是否答對',
    StartTimeOfExperimentalSteps: '實驗步驟開始時間',
    EndTimeOfExperimentalSteps: '實驗步驟結束時間',
    ReasonableUseOfTimeForExperimentalSteps: '實驗步驟合理用時(秒)',
    FullMarksForExperimentalSteps: '實驗步驟滿分',
    NumberOfExperimentalStepsAndOperations: '實驗步驟操作次數',
    ExperimentalStepEvaluation: '實驗步驟評價',
    InspectionPoint: '考察點',
    ModuleName: '模塊名稱',
    Stem: '題幹',
    Score: '得分',
    Remarks: '備註',
    right: '對',
    wrong: '錯',
  },
  project: {
    project_classify: '項目分類',
    implement_classify: '實施分類',
    left_over: '剩餘{0}',
    assign_personnel: '指派人員',
    overdue_not_completed: '逾期未完成',
    time_left: '剩餘時間',
    project_progress: '項目進度',
    total_of: '共{0}場',
    supervisor: '監督人',
    progress_needs: '項目進度需要達到{0}%',
    exams_required_pass: '考試需要及格{0}場',
    cannot_study: '項目未開始不能學習',
    project_overdue: '項目已逾期',
    passing_the_exam: '考試成績及格才是完成',
    stages: '階段{0}',
    details: '詳情介紹',
    catalog: '培訓目錄',
    comments: '學習評論',
    require: '要求',
    not_exist: '抱歉，您訪問的培訓項目不存在。',
    business_account: '業務帳號',
    other_system_business_accounts: '其它係統業務帳號',
    default_Pwd: '默認密碼',
    other_system_address: '其它係統訪問地址',
    empty_tip: '當前帳號暫無業務帳號，請聯係平臺管理員',
    noLearning_tip: '學習通過後才可獲得業務帳號及密碼',
  },
  not_unlocked: '未解鎖',
  map: {
    month: '個月',
    go_pc: '請前往PC端學習！',
    click_unlock: '請在地圖上點擊關卡進行解鎖',
    learning_cycle: '學習週期',
    learn_in_sequence: '按順序學習',
    learn_order: '已開啟 | 未開啟',
    clearance_conditions: '通關條件',
    learning_progress_required: '學習進度要求{0}%',
    pass_exam_num: '考試及格要求{0}場',
    mentor: '幫帶導師',
    Learning_maps_overview: '學習地圖概覽',
    number_exams_passed: '考試通過場數',
    resource_details: '資源明細數',
    no_exist: '抱歉，您訪問的學習地圖不存在。',
    current_level: '當前關卡',
    level_progress: '關卡進度',
    overall_progress: '整體進度',
    nth_people_study: '等{0}人也在學',
    learning_map_desc: '學習地圖說明',
    my_rank_tip: '共 | 名學員一同學習，我的排名是 | ，打敗了',
    my_rank_tip_2: '的學友~',
    level_state: '關卡狀態',
    Learning_period: '學習期限',
    Learning_progress_tip: '已完成的必修和選修資源數之和/要求完成的必修和選修資源數之和',
    pass_condition: '過關條件',
    pass_nth_exam: '{0}場考試通過',
    level_desc: '關卡說明',
    help_and_plan: '幫帶計劃',
    help_and_evaluation: '幫帶評價',
    help_to_summarize: '幫帶總結',
    table: {
      serial: '序號',
      type: '類型',
      name: '名稱',
      status: '學習狀態',
    },
    close_modal_tip: '確定關閉當前學習窗口嗎？',
    evaluation_mentor: '評價導師',
    i_already_know__confirm_done: '我已知曉，確認完成',
    tutor_no_plan: '導師暫未製定計劃',
    submit_summary: '提交總結',
    submit_summary_p: '提交長內容時，建議先用其他軟件編輯好，再粘貼到這裡',
    continue_to_submit: '繼續提交',
    please_fill_out_summary_content: '請填寫總結內容',
    resource_type_learning_type: '資源類型/學習類型',
  },
  newstaff: {
    training_state: '培訓狀態',
    progress: '培訓進度',
    congratulations: '恭喜您完成任務！',
    progress_needs: '培訓進度需要達到{0}%',
    training_overdue: '培訓已逾期',
    no_exist: '抱歉，您訪問的新人培訓不存在。',
  },
  homework: {
    work_requirements: '作業要求',
    step: '第{0}步',
    optional: '（選填）請填寫作業步驟內容，提交長內容時，建議先使用客戶端軟件編輯好，再粘貼到這裡。',
    add_steps: '添加步驟',
    attachment: '{0}個附件',
    review_time: '批閱時間',
    reviewer: '批閱人',
    comments: '評語',
    save_current_step: '請先保存當前編輯的步驟',
    keep_one_step: '至少保留一個步驟',
    upload_attachment_count: '最多可上傳{0}個附件',
    save_tip: '請填寫內容或上傳附件',
    approval: '批閱',
    table: {
      name: '作業名稱',
      score: '作業來源',
      wait: '未批閱/總批閱',
      state: '批閱狀態',
      operate: '操作',
    },
    homework_content: '作業內容',
    score: '分數',
    please_fill_in_the_score: '請填寫分數',
    full_score: '滿分',
    all_approvals_are_complete: '全部批閱完成！',
    successful_approval: '批閱成功',
    sure_delete_step: '確定要刪除該步驟嗎？',
    valuation: '作業評估',
    StudentList: '學員列錶',
    NotEvaluated: '未評估',
    ViewEvaluation: '查看評估',
    AverageScore: '平均分',
    Department: '所屬部門',
    NotStarted: '作業未開始',
    HasEnd: "作業已結束",
  },
  cm_upload: '上傳',
  upload: {
    uploading: '上傳中',
    success: '上傳成功',
    max_size_tip: '文件大小不能超過{0}',
    max_count_tip: '最多上傳{0}個文件',
    failed: '上傳失敗',
    accept: '支持圖片、文檔、音頻、視頻，最多可上傳{0}個附件，單個文件不超過{1}',
    upload_img: '上傳圖片',
    upload_video: '上傳視頻',
    file_siez_cannot_be: '文件大小不能為0',
    please_upload_a_picture_or_video: '請上傳圖片或視頻',
    upload_files: '上傳文件',
    please_upload_the_file: '請上傳文件',
    supported_file_types: '支持文件類型',
    drop_tip: '點擊或直接將文件拖拽到此處上傳。',
    upload_error: '上傳錯誤',
    the_file_format_is_not_supported: 'The file format is not supported',
  },
  quesition: {
    is_submit_tip: '該活動您已參與!',
    my_result: '我的結果',
    view_statistics: '查看統計',
    please_enter: '請輸入',
    view_option_desc: '查看選項說明',
    write_evaluate: '寫評價',
    please_enter_evaluate: '請輸入評價',
    emial_error: '郵箱格式不正確',
    mobile_error: '手機號不正確',
    please_select: '請選擇',
    please_rate: '請打分',
    select_at_least_options: '最少選擇{0}個選項',
    select_up_to_options: '最多選擇{0}個選項',
    cannot_be_less: '不能小於{0}',
    enter_at_least: '最少輸入{0}個字符',
    cannot_be_greater: '不能大於{0}',
    enter_up_to: '最多輸入{0}個字符',
    content_is_incomplete: '內容填寫不完整！',
    quesition_option_required: '第{0}題至少選擇一個標籤!',
    activity_has_expried: '活動已過期，您未參與！',
    please_select_answer: '請選擇答案',
    please_enter_content: '請輸入內容',
    nth_people_have_answered: '已有{0}人回答',
  },
  go_back: '返回',
  second_automatic_jump: '秒自動跳轉',
  practice: {
    Practice: "練習",
    answer_practice: '答題練習',
    answer_record: '答題記錄',
    set_up: '設置',
    mode: {
      daily: '每日答題',
      daily_tip: '太陽每天都是新的',
      interest: '興趣答題',
      interest_tip: '興趣是最好的老師',
      wrong: '錯題集',
      wrong_tip: '在錯題中吸取經驗教訓',
      simulation: '模擬練習',
      simulation_tip: '掌握一定的應試技巧 積纍考試經驗',
      self: '自主練習',
      self_tip: '找出複習漏洞，避免一錯再錯',
    },
    vibration: '振動',
    vibration_tip: '答題過程中振動控製',
    iphone_tip: '蘋果手機若未打開響鈴/靜音模式振動，則無法控製答題模式振動效果。',
    sound_effects: '音效',
    sound_effects_tip: '答題過程中答對答錯有音效效果',
    integral: '積分值',
    question: '題',
    limit_reached: '（積分領取已達上限）',
    error_times: '出錯次數',
    view_tips: '查看提示',
    hide_tips: '隱藏提示',
    answer_analysis: '答案解析',
    number_of_times: '次',
    correct_quesition: '本次答對題目數',
    wrong_quesition: '錯題數',
    use_time: '用時',
    integral_2: '積分',
    one_more_group: '再來一組',
    no_quesition: '暫無練習題目',
    no_wrong_questions_at_moment: '暫無錯題',
    practice_again: '再次練習',
    remove_tip1: '確定要移除錯題？',
    remove_tip2: '移除後將不在錯題練習中出現',
    remove_tip: '確定要移除錯題？\n移除後將不在錯題練習中出現',
    remove_success: '移除成功',
    no_interest: '非答題練習開放時段\n敬請期待',
    select_tags: '選擇感興趣的標簽',
    select_question_bank_directory: '選擇題庫目錄',
    can_select_multiple: '可以選擇多個',
    go_to_answer: '進去答題',
    wrong_question_set: '錯題集',
    view_and_practice: '錯題查看與錯題練習',
    total_wrong_questions: '總錯題',
    view_wrong_question: '查看錯題',
    wrong_question_practice: '錯題練習',
    frequent_wrong_questions: '高頻錯題',
    simulation_exercise: '模擬練習',
    standard_Practice_tests: '標準模擬練習測試',
    total_questions: '總題數',
    total_score: '總分',
    pass_line: '及格線',
    score: '分',
    enter_practice: '進入練習',
    no_simulation: '非模擬練習開放時段\n敬請期待',
    your_choice: '您的選擇',
    time_end_submit: '練習時間已結束，提交成功',
    sure_submit_paper: '您確定要提交試卷嗎？',
    unanswered_to_submit: '還有{0}道試題未答，\n您確定要提交試卷嗎？',
    return_page: '您已成功提交了答卷，將返回上一頁',
    self_practice: '自主練習設置',
    quesition_bank: '題庫總{0}題',
    answer_time: '答題時長',
    paper_total_score: '試卷總分',
    no_self: '非自主練習開放時段\n敬請期待',
    topic_type: '題目類型',
    number_of_answered_questions: '已答題數',
    answer_number_answer_number: '答對數/答題數',
    time: '時間',
    current_location: '當前位置：',
    exit_confirmation: '确定要退出答题？',
    question_number: '題目序號',
    unanswered_to_submit2: '還有{0}道試題未答，\n您確定要提交嗎？',
    please_enter_name_of_question_bank_to_filter: '請輸入目錄名稱篩選',
    enter_quiz: '進入答題',
    transfer_incorrect_question_set: '移出錯題集',
    multiple_choice_question_bank: '選擇題庫',
    practice_record: '練習記錄',
    serial_number: '序號',
    score2: '分數',
    answering_time: '答題用時',
    date: '日期',
    no_practice_records: '暫無練習記錄，快去做練習吧~',
    question_n: '第{0}題：',
    submit: '交卷',
    do_you_want_to_continue_answering_question: '是否接著上一次練習繼續答題？',
    re_answer_question: '重新答題',
    continue_answering_questions: '繼續答題',
    question_unanswered: '還有{0}道試題未答',
    no_test_questions: '冇有可參與練習的試題',
    no_wrong_questions: '暫無錯題',
    no_labels: '暫無標簽',
    no_folders: '暫無目錄',
    generate_test_papers_using_method: '總題數不足，無法生成試卷',
  },
  package: {
    purchase_status: '購買狀態',
    personal_price: '個人價格',
    enterprise_price: '單位價格',
    purchased: '已購買',
    not_purchased: '未購買',
    personal_purchase: '個人購買 | 個人購買',
    enterprise_purchase: '單位購買 | 單位購買',
    minimum_account: '{0}賬號起',
    yuan: '元',
    good_rating: '好評率',
    buyers: '購買人數',
    free_purchase: '免費購買',
    active: '激活',
    price: '單價{0}元',
    less_account: '賬號{0}個起購',
    pay_tip: '支付成功後，您將收到一封激活碼郵件，也可通過單位管理查看激活碼',
    to_pay: '支付',
    form: {
      activation_code: '激活碼',
      activation_code_p: '請輸入{0}位激活碼',
      captcha: '驗證碼',
      captcha_p: '請輸入驗證碼',
      exchange: '確認兌換',
      activation_success: '激活成功',
    },
    purchase_success: '購買成功',
    pay_success: '支付成功',
    min_buy: '單位最低購買',
    people_buy: '{0}人購買',
    good_rate: '好評度',
    after_buy_the_course: '購買課程後',
    is_valid_until: '有效期為',
    code_exchange: '激活碼兌換',
    details: '詳情介紹',
    recommend: '推薦培訓包',
    not_exist: '抱歉，您訪問的培訓包不存在。',
    activation_code_activation: '激活碼激活',
    commodity_order: '商品訂單',
    payment_amount: '支付金額',
    WeChat_payment: '微信支付',
    qr_expires_tip: '距離二維碼過期還剩 | 秒，過期後請刷新頁麵重新獲取二維碼',
    open_phone_wechat_pay: '請打開手機微信，掃一掃完成支付',
    qr_has_expired: '二維碼已過期，請刷新頁麵重新獲取二維碼！',
    scan_code_pay: '掃碼支付',
    table: {
      cover: '封麵',
      name: '培訓包名',
      quantity: '數量',
      price: '價格',
      validity_period: '有效期',
    },
    training_rating: '培訓評分',
    package_has_expired: '培訓包已逾期',
  },
  cm_price: '價格',
  news: {
    have_read: '已讀 | 已閱讀',
    publish_time: '發佈時間',
    hot: '熱',
    new: '新',
    disclaimer: '免責聲明',
    copyright_notice: '版權聲明',
    finish_reading: '完成閱讀',
    no_lickes_allowed: '不允許點贊！',
    no_comments_allowed: '不允許評論！',
    no_exist: '抱歉，您訪問的新聞不存在。',
    news_center: '新聞中心',
  },
  cm_download: '下載',
  cm_downloading: '下載中...',
  cm_loading: '加載中...',
  exam: {
    participation_status: '參與狀態',
    test_name: '主考名稱',
    times: '考試次數',
    unlimited: '不限次數',
    overdue_can_learn: '逾期後可學',
    make_up_exam: '補考安排',
    enter_make_ip: '進入補考',
    not_yet_exam_time: '還未到考試時間',
    total_score: '考試總分',
    total_score_tip: '本次考試總分為',
    cut_screen_tip: '不允許切屏。',
    cut_screen_tip_2: '本場考試切屏次數為{0}次。',
    rule_unlimited: '無限次',
    cut_screen_tip_3: '超過切屏次數規則係統會強製交卷。',
    exam_record_view: '考試記錄查看',
    exam_record_view_tip: '交卷後可查看答卷。',
    exam_record_view_tip_2: '及格後可查看答卷。',
    exam_record_view_tip_3: '考試結束後可查看答卷。',
    exam_record_view_tip_4: '不允許查看答卷。',
    mandatory_submission_rules: '強製交卷規則',
    mandatory_submission_rules_tip: '進入答題界麵開始計時, 倒計時結束未自主交卷則係統強製交卷',
    mandatory_submission_rules_tip_2: '進入答題界麵開始計時，未交卷退出答題界麵，考試用時會繼續計時，用時結束係統會強製交卷',
    study_reward: '學習獎勵',
    study_reward_tip: '考試通過可獲得證書',
    last_count: '剩餘次數',
    cannot_exam: "暫不可考試",
    exam_is_over: '考試已結束',
    exam_result: '考試結果',
    exam_result_query: '考試結果查詢',
    format: {
      d: '天',
      h: '時',
      m: '分',
      s: '秒',
      now: '剛剛',
      m_ago: '{0} 分鐘前',
      h_ago: '{0} 小時前',
      d_ago: '{0} 天前',
      month_ago: '{0} 個月前',
      y_ago: '{0} 年前',
      hh: '小時',
      mm: '分鐘',
    },
    make_up_pass: '補考通過',
    view_exam_sheet: '考試及格才能查看答卷 | 考試結束才能查看答卷',
    clear: '清除',
    exam_signature: '考試簽名',
    exam_signature_tip: '本場考試開啟了"簽名確認"，請在下麵簽名框內使用鼠標籤名（長按鼠標）。',
    please_sign: '請簽名',
    signed_successfully: '簽名提交成功',
    signature_failed: '簽名提交失敗',
    right_answer: '正確答案',
    case_title: '本大題共{0}小題，總分{1}分',
    expand_case: '展開全部題目',
    view_pic: '點擊查看圖片',
    view_video: '點擊觀看視頻',
    view_audio: '點擊收聽音頻',
    pre_quesition: '上一題',
    keyword: '關鍵詞',
    score_keywords: '判分關鍵詞',
    single_choice: '單選題',
    multiple_choice: '多選題',
    save: '暫存',
    no_quesition: '暫無試題',
    doexam_cut_screen_tip: '請註意，本次考試不允許切屏',
    doexam_cut_screen_tip_2: '請註意，考試切屏次數還剩{0}次，切屏時間${1}',
    mandatory_submission: '再切屏將會強製交卷!',
    whether_save_paper: '是否保存答卷',
    submit_paper_wait: '交卷中，請稍等',
    in_approval: '待審批',
    my_answer: '我的答案',
    not_score: '未得分',
    quesition_num: '第{0}題',
    fill_in_blanks: '填空',
    not_answer: '未答題',
    answers: '答題答案',
    score_ratio: '得分比例',
    exam_info: '考試信息',
    exam_name: '考試名稱',
    approval_count: '批閱數',
    approval_progress: '批閱進度',
    answer_info: '答卷信息',
    student_name: '學員姓名',
    exam_time: '開考時間',
    exam_score: '考試得分',
    successful_approval: '閱卷成功',
    all_approved: '全部已批閱',
    to_score: '得分',
    score: '分',
    nth_score: '{0}分',
    exam_not_started: '考試未開始',
    note: '註',
    exam_description: '考試說明',
    no_description: '暫無說明',
    submit_time: '交卷時間',
    use_time: '考試用時',
    nth_s: '{0}秒',
    exam_err_tip: '抱歉，您訪問的考試{0}',
    daily_err_tip: '抱歉，您訪問的每日一練{0}',
    total_duration: '總時長',
    auto_scoring_tip: '若有主觀題，係統不支持自動判分',
    submit: '交卷',
    unanswered_to_submit: '還有{0}道試題未答 | 確定要提交試卷嗎？',
    exam_time_has_end: '考試時間已結束,正在提交試卷...',
    exam_map_submit: "您已成功提交了答卷,將返回學習地圖",
    status_of_this: '本次考試狀態',
    exam_status: '本場考試狀態',
    passing_score: '及格分數',
    join_nums: '參與次數',
    export_answer: '導出答卷',
    ios_export_tip: '蘋果電腦不支持導出，請瀏覽器訪問平臺進行導出操作',
    my_scoring: '我的得分',
    modify_score_tip: '確定修改題目的得分嗎？',
    modify_score_tip2: '確定修改題目的得分為',
    modify_score_tip3: '分嗎？',
    exam_again: '再考一次',
    screen_cut_time: '切屏時間',
    screen_cut_time_tip: '超過切屏時間係統會強製交卷',
    infinitely: '不限製',
    examPassword: '考試密碼',
    examPasswordError: '考試密碼錯誤',
    PleaseNoteThatTheExamScreenCutTimeIsNthSeconds: '請註意，考試切屏時間為{0}秒'
  },
  has_been_deleted: '已刪除',
  unpublished: '已取消發佈',
  not_exist: '不存在',
  no_permission: '沒有參與權限',
  signup: {
    unlimited: '無限製',
    people: '{0}人',
    training_introduction: '培訓介紹',
    training_arrangement: '培訓安排',
    do_need_review: '是否需要審核',
    need: '需要',
    no_need: '不需要',
    training_time: '培訓時長',
    sign_time: '報名（啟）止時間',
    sign_scope: '報名範圍',
    company_wide: '全公司',
    contains_subdepartments: '包含子部門',
    project_introduction: '項目介紹',
    reason_for_ejection: '駁回原因',
    signup_start_end: '距離報名開始還有 | 距離報名結束還有',
    signup_end: '報名已截止',
    start_sign_up: '開始報名',
    cancel_sign_up: '取消報名',
    afresh_sign_up: '重新報名',
    subject_no_start: '專題未開始不能學習',
    subject_has_expired: '專題已過期, 無法繼續學習',
    sure_sign_up: '確定報名嗎？',
    sure_cancel_sign_up: '確定取消報名嗎？',
    cancel_sign_up_success: '取消報名成功',
    cancel_sign_up_fail: '取消報名失敗',
    registration_not_started: '報名未開始',
  },
  cm_sign_up: '報名中心',
  sign_up_details: '報名詳情',
  lecturer_center: '講師中心',
  lecturer: {
    level: '講師等級',
    classify: '講師分類',
    view_lecturer: '查看講師',
    basic_info: '基本信息',
    online_courses: '在線課程',
    offline_training: '線下授課',
    honor: '講師榮譽',
    empty: '無',
    lecturer_profile: '講師簡介',
    expert_title: '專家稱號',
    areas_of_expertise: '擅長領域',
    teaching_direction: '授課方嚮',
    field_of_study: '研究領域',
    other: '其他',
    none: '無',
    work_experience: '工作經歷',
    professional_rank: '專業隊伍職級',
    model_project_team_rank: '型號項目隊伍職級',
    course_duration: '課程時長',
    address: '授課地點',
    not_exist: '抱歉，您訪問的講師不存在。',
  },
  subject: {
    learners: '學習人數',
    people: '人',
    time_range: '時間範圍',
    no_study_time_limit: '無學習時間限製',
    detail: '專題詳情',
    arrange: '專題安排',
    stages: '{0}個階段',
    course_total: '共{0}門課程',
    learning_progress_reaches: '學習進度達到{0}%',
    learning_time: '學習時間',
    study_certificate: '學習證書',
    intro: '專題介紹',
    learning_progress: '學習進度',
    chapter_situation: '章節情況',
    start: '開始',
    course_not_exist: '抱歉，您訪問的課程專題不存在',
    learned: '已學習{0}%',
    nth_people_have_learned: '{0}人已學習',
    special_introduction: '專題簡介',
    recommended_subject: '推薦專題',
    course_no_permission: '抱歉，您沒有該課程專題的學習權限，請聯繫管理員',
    overdue_tip: '專題已過期，無法繼續學習',
    not_start_tip: '專題未開始不能學習',
  },
  activity: {
    type: '活動類型',
    investigation: '調查問卷',
    evaluate: '評估錶',
    vote: '投票',
    total_activity: '共 | 個活動',
    assign_me_one: '給我指派了一個',
    has_expired: '活動已逾期',
    not_started: '活動暫未開始',
    participate: '參與活動',
  },
  to_be_done: '待完成',
  cm_overdue: '已逾期',
  cm_to: '至',
  shop: {
    my_exchange_record: '我的兌換記錄',
    integral_rule: '積分兌換規則',
    exchange_number: '每人可兌換數',
    exchange_up_to: '最多可兌換{0}個',
    sure_exchange: '確定兌換嗎？',
    exchange_status: '兌換狀態',
    points_required: '所需積分',
    added_time: '上架時間',
    stock: '庫存',
    commodity_detail: '商品詳情',
    commodity_stock: '商品庫存',
    pieces: '件',
    people_exchange_num: '此商品每人僅限兌換{0}件',
    have_exchange_num: '已兌換{0}件',
    already_exchange: '已經兌完',
    the_product_details: '商品詳細',
    guess_you_like: '猜你喜歡',
    integral_not_enough: '您的積分不足',
    table: {
      commodity_intro: '商品簡介',
      single_integral: '單個積分',
      consumption_integral: '消費積分',
      exchange_quantity: '兌換數量',
      exchange_time: '兌換時間',
    },
  },
  live: {
    to_be_completed_live: '待完成直播',
    history_live: '歷史直播',
    live_status: '直播狀態',
    learning_status: '學習狀態',
    required_study_time: '要求學習時長',
    watch_time: '已觀看時長',
    live_lecturer: '直播講師',
    speaker: '主講',
    speaker2: '主講人',
    participant: '參與者',
    live_detail: '直播詳情',
    live_time: '直播時間',
    live_not_started: '直播未開始',
    live_has_ended: '直播已結束',
    view_replay: '查看回放',
    no_exist: '直播不存在',
    start_client: '啟動客戶端',
    web_live: '網頁開播',
    start_live: '開啟直播',
    ios_tip: '蘋果電腦不支持開啟直播，請用企微直播應用開啟直播',
    wxwork_tip: '請用企業微信開啟直播',
    is_open_dingding_live: '正在打開釘釘直播...',
    view_live: '查看直播',
    wxwork_tip2: '請用企業微信查看直播',
    wxwork_tip3: '請用企業微信參與直播',
  },
  cm_creator: '創建人',
  train: {
    schedule: '麵授培訓日程錶',
    progress: '麵授進度',
    scan_code_attendance: '掃碼考勤',
    click_scan_qr: '點我掃描二維碼，快速錄入考勤',
    signed_out: '已簽退',
    attendance: '考勤',
    head: '考勤負責人',
    not_exist: '麵授不存在',
    on_camera_permission: '請前往設置開啟相機權限',
    lesson_hours: '課時',
    lecturer: '授課講師',
    lesson_rating: '授課評分',
    class_place: '上課地點',
    attendance_time: '簽到簽退時間',
    no_sign: '未簽到',
    no_sign_out: '未簽退',
    course_detail: '課程詳情',
    course_comment: '課程討論',
    activity_attachments: '活動附件',
    test_results: '考核成績',
    lecture_comments: '講師評語',
    activity_photos: '活動照片',
    other_attachments: '其它附件',
    view_lecture_appraise: '查看講師評價',
    evaluation_lecturer: '評價講師',
    view_course_appraise: '查看課程評價',
    evaluation_course: '評價課程',
    enter_meeting: '進入會議',
    ask_for_leave: '請假',
    cancel_leave: '取消請假',
    approving_for_leave: '請假審批中',
    exam_results: '考試成績',
    teacher_comment: '老師評語',
    view_full: '查看完整',
    sign_in_time: '簽到時間',
    sign_out_time: '簽退時間',
    not_rated: '未評分',
    lecturer_rating: '講師評分',
    view_reviews: '查看評價',
    evaluation: '評價',
    no_exist: '抱歉，您訪問的麵授不存在。',
    please_use_enterprise_wechat_to_participate_in_meeting: '請用企業微信參與會議',
    is_open_dingding_meeting: '正在打開釘釘會議...',
    offline_grades: '線下成績',
    attendance_info: '考勤信息',
    sign_in_qr: '簽到二維碼',
    sign_out_qr: '簽退二維碼',
    grade_infor: '成績信息',
    pass_score: '及格分',
    entered: '已錄入',
    not_entered: '未錄入',
    dynamic_q_r_code: '動態二維碼，每{0}秒刷新一次',
    sign_in_status: '簽到狀態',
    sign_out_status: '簽退狀態',
    attendance_status: '出勤狀態',
    batch_operation: '批量操作',
    import_attendance: '導入考勤',
    be_late: '遲到',
    leave_early: '早退',
    absent: '未出勤',
    attended: '已出勤',
    batch_set_attendance: '當前為所選{0}人批量進行人工考勤設置',
    please_select_sign_in_time: '請選擇簽到時間',
    please_select_sign_out_time: '請選擇簽退時間',
    attendance_temp: '請先點擊 | 下載考勤模闆 | ，編輯完內容後上傳模闆導入考勤',
    select_file: '選擇文件',
    reselect: '重新選擇',
    drop_file_attendance: '可直接將文件拖拽到此處上傳，支持文件類型：xls .xlsx',
    student: '學員',
    please_select_a_student: '請勾選學員',
    checked_one: '至少勾選一項複選框的內容',
    wrong_file_type: '文件類型錯誤,請選擇xls/xlsx文件',
    please_upload_template_file: '請上傳模闆文件',
    operation_completed_without_importing_any_data: '操作完成，未導入任何數據！',
    import_nth_data: '操作完成，成功導入{0}條數據',
    attendance_template: '考勤模闆.xlsx',
    has_been_evaluated: '已評價',
    not_evaluated: '未評價',
    evaluation_status: '評價狀態',
    detail: '評價學員詳情',
    grading_time: '評分時間',
    grade_status: '成績狀態',
    view_grade: '查看成績',
    enter_grades: '錄入成績',
    enter_grades_for: '當前正在為 | 錄入成績',
    grades: '成績',
    qualified_status: '合格狀態',
    pass_status: '及格狀態',
    entered_successfully: '錄入成功',
    attachment_accept: '支持圖片、文檔、音頻、視頻，壓縮包，最多上傳20個附件，單個文件不超過1G',
  },
  cm_contest: '答題競賽',
  contest: {
    select_activity: '選擇活動',
    ba: '吧',
    not_open: '非答題競賽開放時段',
    stay_tuned: '敬請期待！',
    activity_desc: '活動介紹',
    record: '答題記錄',
    my_gold_coin: '我的金幣',
    qualifying: '排位賽',
    qualifying_desc: '闖關答題，強者登頂',
    foursome: '四人賽',
    foursome_desc: '在線對抗，強者勝！',
    vs_desc: '雙人對戰，孰強孰弱！',
    one_stop: '一站到底',
    one_stop_desc: '答錯離場，答對繼續',
    leaderboard: '排行榜',
    not_unlocked: '尚未解鎖',
    not_open_yet: '暫未開放，敬請期待',
    opening_time: '開放時間段為',
    vs: '雙人比賽',
    challenge_Record: '挑戰記錄',
    gold_coin: '金幣',
    ranking_stars: '排位星星',
    have_not_participated: "還沒有參與過比賽",
    go_game: "快去比一局吧",
    learning_from_the_old: '溫故知新',
    quesition: '第 | 題',
    right: '回答正確',
    wrong: '回答錯誤',
    rule_desc: '規則說明',
    updated_in_real_time: '數據實時更新',
    no_one_on_list: '還沒有人登上榜單',
    go_answer: '快去答題上榜吧',
    not_yet_on_the_list: '尚為上榜',
    no_nth_list: '榜上排名第 | 位',
    challenge_now: '立即挑戰',
    ranking_rules: '排名規則',
    leaderboard_ranking_rules_1: '展示活動參與人員所有排行信息。',
    leaderboard_ranking_rules_2: '段位越高、星星越多、勝率越高的用戶排名越高',
    leaderboard_ranking_rules_3: '排行榜實時更新',
    leaderboard_ranking_rules_4: '註意：隻有排位賽獲勝才會加星',
    warm_tip_1: '用戶成功匹配對手即進入答題流程。手機故障、網絡不佳等異常情況會影響比賽得分、排名和金幣，建議檢查手機設備並在良好的網絡環境下參與答題',
    entry_coins: '入場金幣',
    win_reward: '獲勝獎勵',
    failure_reward: '失敗獎勵',
    competition_rules: '比賽規則',
    qualifying_competition_rule_1: '每局在線匹配用戶1人、匹配成功開始作答',
    qualifying_competition_rule_2: '每題分值{0}分，每題答題時間{1}秒',
    qualifying_competition_rule_3: '每局比賽共{0}題，兩人對戰，總得分越高的獲勝，獲勝即可獎勵一個星星',
    qualifying_competition_rule_4: '每局比賽失敗，會扣掉一顆星 | 每局比賽失敗，不會掉星',
    qualifying_competition_rule_5: '每局全部用戶比賽結束，方可生成答題記錄；中途退出提前結束的用戶請等比賽結束後查看答題記錄',
    qualifying_ranking_rules_1: '星星數越多的用戶在排行榜上排名越高',
    warm_tip_2: '蘋果手機左滑無法正常退出答題流程，請點擊頁麵左上角返回按鈕離開擂臺',
    game_not_open: '比賽未開啟',
    enough_gold_coins: '金幣不足，無法參與！',
    conins_for_place: '金幣局第一名{0}個，第二名{1}個，第三名{2}個, 第四名{3}個',
    challenger: '擂主',
    good_network: '網絡良好',
    poor_network: '網絡較差',
    start_game: '開始比賽',
    gold_coins_today: '今日金幣獎勵局',
    foursome_competition_rule_1: '每局在線匹配用戶4人、匹配成功開始作答',
    foursome_competition_rule_2: '每題答對+{0}分，答錯不減分，優先獲得100分即為勝利，獲得金幣獎勵',
    foursome_competition_rule_3: '每局比賽最多10分鐘，10分鐘內無選手達到100分則全部失敗',
    foursome_competition_rule_4: '每局全部用戶比賽結束，方可生成答題記錄；中途退出提前結束的用戶請等比賽結束後查看答題記錄',
    invite_friends: '邀請好友',
    compete_with_friends: '與好友切磋',
    random_match: '隨機匹配',
    fight_passersby: '和路人對戰',
    vs_competition_rule_1: '點擊空位邀請一名好友或用戶（隨機）參與對戰，擂主具備開局權限',
    vs_competition_rule_2: '每天金幣獎勵{0}局，獲勝+{1}金幣，失敗+{2}金幣',
    vs_competition_rule_3: '註意：參與雙人對戰不加星，成績不計入排行榜',
    wrong_leave_right_continue: '答錯離場，答對繼續',
    matching: '匹配中...',
    successful_match: '匹配成功',
    wait_other_answer: '等待對方答題',
    highest_scorer: '得分高者',
    first_100: '優先到達100分',
    all_right: '全部答對',
    will: '即為獲勝',
    readying: '準備',
    start: '開始',
    quesition_num: '第{0}題',
    consecutive_right: '連續答對x{0}',
    congratulations: '恭喜答對啦',
    wrong_answer: '這題答錯咯',
    congratulations_to_you: '恭喜你',
    get_win: '獲得{0}的勝利',
    unfortunately: '很遺憾',
    win_next_time: '不要氣餒，下次再贏',
    challenge_success: '挑戰成功',
    challenge_failed: '挑戰失敗',
    escaped: '已逃跑',
    leave_page: '{0}秒後或點擊任意地方離開此頁麵',
    victory: '獲得勝利',
    keep_on: '繼續加醫',
    review_answer: '回顧本局答題',
    challenge_again: '再次挑戰',
    choose_colleagues: '選擇同事',
    recently_played: '最近對戰',
    company_colleagues: '公司同事',
    send_invite: '發送邀請',
    challenge_ends: '挑戰結束',
    answer_quesitions_time: '本次答對 | 題',
    highest_answer_quesitions: '歷史最高答對{0}題',
    gold_coin_limit: '金幣已達上限',
    end_the_game: '結束本局',
    ranking_result_tip_1: '優先分數排名',
    ranking_result_tip_2: '如果分數一樣，優先答題用時短的獲勝',
    ranking_result_tip_3: '分數和用時一樣，優先最後一題誰先答的獲勝',
    ranking_result_tip_4: '逃跑和最後一題掉線，算對方贏，不論分數高低',
    back_tip_1: '確定要離開擂臺嗎？',
    back_tip_2: '請問您確定要退出嗎，中途退出將視為比賽失敗，稍後請到金幣明細查看金幣情況。',
    continue: '繼續',
    quit: '退出',
    insufficient_gold_coins: '金幣不足',
    rank_not_unlocked: '段位未解鎖',
    please_select_colleagues: '請選擇要邀請的同事',
    sure_invite_select: '確定邀請當前選中人員嗎？',
    invite_you_participate: '{0}邀請你參加雙人賽～',
    the_battle_over: '對戰結束，沒有更多題目了！',
    init_rule: {
      tip_1: '優先獲得100分，即為獲勝',
      tip_2: '答對+{0}分，答錯不減分',
      tip_3: '在線對抗，能者勝',
      tip_4: '資源加載中，馬上開始',
      tip_5: '每題分值{0}分',
      tip_6: '每局比賽共{0}題',
    },
  },
  bbs: {
    no_more: '已經到底了',
    please_enter_title_author: '請輸入標題/作者',
    post: '帖子',
    ask: '提問',
    article: '文章',
    my_circle: '我的圈子',
    create_circles: '創建學習圈',
    find_circle: '發現圈子',
    topic: '主題',
    topic_2: '話題',
    member: '成員',
    top_zone: '置頂專區',
    top: '置頂',
    essence: '精華',
    admin: '管理員',
    circle_owner: '圈主',
    latest_content: '按最新',
    recommended_content: '按推薦',
    hot_content: '按熱度',
    latest_discussion: '按討論',
    only_view_owner: '隻看圈主',
    anonymous_user: '匿名用戶',
    invited: '邀請',
    invited_2: '邀請了',
    been_invited_1: '等{0}位被邀請',
    been_invited_2: '被邀請',
    form: '來自{0}',
    internal_link: '內部鏈接',
    external_link: '外部鏈接',
    attachment: '附件{0}',
    video: '視頻{0}',
    reward_answer_integral: '懸賞回答{0}積分',
    publish: '發佈',
    file_status_1: '未轉換 | 待轉換',
    file_status_2: '轉換中 | 轉換失敗',
    i_will_answer: '我來回答',
    i_will_comment: '我來評論',
    leave_app_tip: '您即將離開此頁麵，請註意賬號安全',
    zip_not_preview: 'zip文件不支持預覽',
    join_corcle_like: '加入圈子才能點贊哦！',
    please_publish: '請先發佈',
    join_circle_view_detail: '加入圈子才能查看主題詳情哦',
    join_circle_can: '加入圈子才能回答哦 | 加入圈子才能評論哦',
    my: {
      publish: '我發佈的',
      draft: '我的草稿',
      follow: '我關註的',
      collect: '我收藏的',
      comment: '評論我的',
      answer: '回答我的',
      like: '點讚我的',
      invited: '邀請我的',
    },
    published: '已發佈的',
    unpublished: '未發佈的',
    invite_me_follow: '邀請我關註{0}',
    my_answer: '我的回答',
    my_comment: '我的評論',
    liked: '已點贊',
    liked_2: '讚了',
    this: '這個',
    i_manage: '我管理的',
    waiting_review: '等待審核',
    apply_join: '申請加入',
    circle_profile: '圈子簡介',
    num_members: '{0}位成員',
    after_join: '加入後，可瀏覽精彩內容',
    member_manage: '成員管理',
    invite_people: '邀請人員',
    disband_circle: '解散圈子',
    quit_circle: '退出圈子',
    invite_you_join_circle: '邀請你加入學習圈',
    scan_code_enter_circle: '掃碼立即進入圈子',
    link_browser: '或瀏覽器輸入鏈接',
    copy_link: '複製鏈接',
    save_card: '保存邀請卡',
    disband_circle_tip: '確定解散圈子嗎？',
    disbanded_successfully: '解散成功！',
    disbanded_failed: '解散失敗！',
    quit_circle_tip: '確定退出圈子嗎？',
    quit_successfully: '退出成功！',
    quit_failed: '退出失敗！',
    copy_successfully: '複製成功',
    back_circle: '返回圈子',
    please_enter_name_account_depart: '請輸入姓名、賬號、部門搜索',
    topics: '主題數',
    inviter: '邀請人',
    join_time: '加入時間',
    apply_time: '申請時間',
    process_time: '處理時間',
    invite_time: '邀請時間',
    process_opinion: '處理意見',
    remove: '移除',
    set_admin: '設置管理員',
    cancel_admin: '取消管理員',
    agree: '同意',
    reject: '拒絕',
    has_invited: '已邀請',
    sure_agree: '確定同意嗎？',
    sure_reject: '確定拒絕嗎？',
    please_enter_review: '請輸入審核意見（選填）',
    sure_remove: '確定要移除嗎？',
    invite_successful: '邀請成功',
    has_joined: '已加入',
    pending_review: '待審核',
    has_rejected: '已拒絕',
    selected: '已選擇',
    select_most_people: '最多選擇{0}個人員',
    person_is_select: '已選擇此人員',
    please_enter_title_name_content: '請輸入標題/作者/內容',
    follow: '關註話題 | 關註',
    unfollow: '取消關註 | 取消關註',
    sure_follow_topic: '確定{0}話題嗎？',
    follow_successfully: '{0} 成功',
    follow_failed: '{0} 失敗',
    search_you_interested: '搜索你感興趣的內容...',
    search_history: '搜索歷史',
    view_all: '查看全部',
    like_2: '贊',
    quesition: '問題',
    follow_quesition: '關註問題',
    featured_answer: '精選回答',
    comment_time: '評論時間',
    comment_hot: '評論熱度',
    answer_time: '回答時間',
    answer_hot: '回答熱度',
    post_detail: '帖子詳情',
    ask_detail: '問答詳情',
    article_detail: '文章詳情',
    global_top: '全局置頂',
    set_global_top: '改為全局置頂',
    circle_top: '圈內置頂',
    set_circle_top: '改為圈內置頂',
    cancel_top: '取消置頂',
    refined: '加精',
    cancel_refined: '取消加精',
    close_quesition: '關閉問題',
    quesition_has_close: '該問題已關閉',
    edit: '編輯',
    delete: '刪除',
    sure_delete: '確定刪除嗎？',
    i_want: '我要',
    only_owner_can: '僅圈主可以 {0}',
    only_owner_admin_can: '僅圈主/圈子管理員可以 {0}',
    adopted: '被採納',
    featured_recommended: '精選推薦',
    replied: '回覆了',
    in_total: '等{0}位',
    view_replied: '查看{0}條回覆',
    adopt: '採納',
    recommend: '推薦',
    adpoted_cannot_del: '被採納的回覆不能被刪除',
    after_adpot_cannot_cancel: '採納後不可取消，確定要採納嗎？',
    adopted_successfully: '採納成功',
    after_recommend_cannot_cancel: '推薦後不可取消，確定要推薦嗎？',
    recommended_successfully: '推薦成功',
    say_you_want: '說點你想說的',
    remind_who_watch: '提醒誰看',
    remind_who_watch_tip: "{'@'}的人會收到消息提醒",
    anonymous_reply: '匿名回覆',
    anonymous_answer: '匿名回答',
    anonymous_comment: '匿名評論',
    reply_detail: '回覆詳情',
    join_now: '立即加入',
    enter_circle: '進入圈子',
    sure_agree_join: '確定同意加入嗎？',
    please_enter_topic_name: '請輸入話題名稱',
    max_choose_topic: '最多選擇{0}個',
    group: {
      cover: '圈子封麵',
      cover_tip: '建議尺寸130*130，文件不超過1MB的jpg、jpeg、png圖片',
      please_upload_select: '請上傳或選擇封麵',
      name: '圈子名稱',
      please_enter_name: '請輸入圈子名稱',
      intro: '圈子簡介',
      intro_p: '請輸入圈子簡介',
      owner: '圈主簡介',
      owner_p: '請輸入圈主簡介',
      access_permission: '訪問權限',
      join_limit: '加入限製',
      browse_settings: '瀏覽設置',
      post_subject_permission: '發佈主題權限',
      comment_reply_permission: '評論回答回覆權限',
      member_info: '成員信息',
      anonymous_settings: '匿名設置',
      permission_settings: '權限設置',
      allow_anonymous_post_subject: '允許匿名發佈主題',
      allow_anonymous_comment_answer_reply: '允許匿名評論/回答/回覆',
      allow_anonymous_all: '允許匿名評論/回答/回覆',
      no_anonymity_allowed: '不允許匿名',
      not_save_leave: '內容未保存，是否退出',
      created_successfully: '創建圈子成功，跳轉中..."',
      created_failed: '創建圈子失敗',
      public_circle: '公開圈子',
      public_circle_tip: '所有用戶均可訪問',
      private_circle: '私密圈子',
      private_circle_tip: '不在發現圈子中展示',
      no_need_review: '無需審核',
      no_need_review_tip: '所有用戶允許加入',
      need_review: '需要審核',
      need_review_tip: '所有用戶加入圈子需要審核',
      specify_user_to_join: '指定用戶加入',
      specify_user_to_join_tip_2: '其他用戶無法申請加入',
      specify_user_to_join_tip: '隻能圈子和圈子管理員邀請加入',
      allow: '允許未加入用戶預覽部分主題',
      allow_tip: '勾選後，允許未加入用戶瀏覽三遍精華或最新發佈的主題',
      not_allowed: '不允許',
      not_allowed_tip: '不允許未加入用戶瀏覽分主題',
      all_people: '所有人',
      circle_owner_only: '僅圈主',
      owner_admin: '圈主+管理員',
      open_member_list: '開放成員列錶',
      open_member_list_tip: '勾選後，在圈子主頁展示成員列錶',
      closed_member_list: '不開放成員列錶',
      allow_anonymity: '允許匿名',
      contact_owner_join_circle: '你還未加入該圈子且該圈子是私密圈子，請聯繫圈主加入圈子！',
      the_circle_is_created: '恭喜您，圈子創建好了！',
      the_circle_is_created_tip: '在這裡，可邀請成員加入您的圈子',
      the_circle_is_created_tip_2: '作為圈主，請發佈本圈子的第一個主題吧',
    },
    addpost: {
      title: '標題',
      please_enter_title: '請輸入標題',
      title_less_tip: '標題不得少於{0}個字',
      content: '內容',
      text: '正文',
      please_enter_text: '請輸入正文',
      text_less_tip: '正文不得少於{0}個字',
      text_more_tip: '正文不得大於{0}個字',
      link: '鏈接',
      allow_download: '允許下載',
      upload_cover: '上傳封麵',
      upload_cover_tip: '建議尺寸：260*150；大小不超過1M',
      upload_image: '上傳圖片',
      select_circle: '選擇圈子(必填)',
      reward_points: '懸賞積分',
      post_anonymously: '匿名發貼',
      add_link: '添加鏈接',
      please_enter_link: '請輸入鏈接',
      link_start: '鏈接請以http://或者https://開頭',
      link_tip: '請註意，您正在分享一個外部鏈接，所有成員在第三方網站提交的信息將由該網站依據其隱私、保密和安全條款執行並負責。',
      select_bonus_points: '選擇懸賞積分',
      please_enter_reward_points: '請輸入懸賞積分',
      available_points: '可用積分',
      select_image: '選擇圖片',
      select_video: '選擇視頻',
      content_less_tip: '內容不得少於{0}個字',
      post: '發帖子',
      ask: '提問題',
      article: '寫文章',
      failed_get_data: '獲取數據失敗',
      url_format_incorrect: 'Url格式不正確',
      topic: '話題',
      select_topic: '選擇話題',
      click_enter_create_topic: '，或點擊回車新建話題',
      choose_up_topics: '最多選擇{0}個話題',
      this_topic_is_selected: '已選擇此話題',
      less_word_topic: '話題不得少於{0}個字',
      input_positive_integer: '請輸入正整數',
      insufficient_points: '積分不足',
      circle: '圈子',
      please_select_circle: '請選擇圈子！',
      published_successfully: '發佈成功',
      publish_failed: '發佈失敗',
      choose_person: '選擇人員',
      please_choose_person: '請選擇人員',
      invite_up_to_nth_people: '最多邀請{0}位',
      picture_tip: '己上傳{0}張，最多上傳{1}張，支持jpg、jpeg、png、gif格式，每張不超過{2}',
      video_tip: '請上傳{0}以下的視頻 | 支持上傳MP4、AVI、MOV、WMV、RM(VB)、FLV格式',
      file_tip: '己上傳{0}張，最多上傳{1}張，支持pdf、ppt、pptx、doc、docx（單個文檔小於100MB）、zip（單個附件小於1G）',
      parse: '解析',
      delete_link_tip: '確認刪除鏈接嗎？',
      save_draft: '存草稿',
    },
    not_open_join: '該學習圈暫未開放加入！',
    sure_join: '確定申請加入嗎？',
    application_successful: '申請成功！',
    application_failed: '申請失敗！',
    has_application_wait: '已申請成功，請耐心等待審核！',
    nth_people: '...等{0}人',
    click_download: '點擊下載',
    add_post: '發帖子',
    add_ask: '提問題',
    add_article: '發文章',
    not_post_tip: '內容發佈功能已被關閉，如需使用，請與管理員聯繫！',
    nth_answer: '{0}個回答',
    confirm_cancel: '確認取消',
    confirm_cancel_tip: '當前操作尚未保存，是否確認取消？',
    invite_members: '邀請成員',
    invite_members_tips: '請輸入姓名或者賬號進行邀請',
    agree_to_join: '同意加入',
    have_applied: '已申請',
    best_sharer: '最佳分享者',
    related_osts: '相關帖子',
    member_list: '成員列錶',
    please_enter_account_name_department_to_search: '請輸入賬號/姓名/部門搜索',
    batch_remove: '批量移除',
    remove_selected_nth_people: '確定要移除選中的{0}人嗎',
    click_answer: '點擊回答',
    click_comment: '點擊評論',
    toggle_follow: '關註 | 取消關註',
    toggle_like: '點贊 | 取消點贊',
    toggle_collect: '收藏 | 取消收藏',
    all_comments: '全部評論',
    all_answers: '全部回答',
    by_time: '按時間',
    mentioned: '提到了',
    nth_replies: '{0}條回覆',
    hot_topic: '熱門話題',
    are_you_sure_to_delete: '確認刪除嗎？',
    post_count: '帖子數',
    quesition_count: '問題數',
    article_count: '文章數',
  },
  hot_sign_up: '火熱報名',
  loading: '數據加載中...',
  has_send_to: '已發送至',
  login_successful: '登錄成功',
  login_failed: '登錄失敗',
  login_failed_not_admin: '登錄失敗；賬號未開通管理權限',
  confirm_login_you: '請確認使用本人賬號登錄',
  login_confirm: '登錄確認',
  sorry: '抱歉',
  page_not_exist: '您訪問的頁麵不存在',
  update_tip: '更新提示',
  update_content: '新版本已經準備好，是否重啟應用？',
  new_version: '已經有新版本了喲',
  new_version_content: '新版本已經上線啦~，請您刪除當前小程序，重新搜索打開喲~',
  all_task: '全部任務',
  cm_pre: '上一頁',
  cm_next: '下一頁',
  face: {
    pending_face: '待完成麵授',
    history_face: '歷史麵授',
    please_enter: '請輸入麵授名稱',
    type: '麵授類型',
    state: '麵授狀態',
    online: '線上授課',
    offline: '線下授課',
    mix: '混合授課',
    study_state: '學習狀態',
    face_time: '麵授時間',
    address: '培訓地址',
  },
  view_detail: '查看詳情',
  no_more: '沒有更多了',
  load_more: '加載更多',
  please_select_data: '請選擇數據',
  issued_independently: '獨立下發',
  teaching: {
    helping_task: '幫帶任務',
    learning_track: '學習跟蹤',
    table: {
      map_name: '學習地圖名稱',
      schedule_completion: '安排計劃完成',
      evaluate_students: '評價學員完成',
      summarize: '總結完成',
      operate: '操作',
      name_account: '姓名（賬號）',
      department: '部門',
      resource_name: '資源名稱',
      status: '狀態',
      completion_standard: '完成標準',
      finish: '完成',
      in_progress: '進行中',
      overdue: '逾期',
      completion_rate: '完成率',
      pass_rate: '通過率',
      participation_rate: '參與率',
      name: '姓名',
      account: '賬號',
      position: '崗位',
      whether_to_pass: '是否通過',
      learning_progress: '學習進度',
      study_completed: '學習完成',
      pass_num: '及格場次',
      online_study_time: '線上學習時長',
      exam_time: '考試用時',
      stage_progress: '階段進度',
    },
    arranged: '已安排',
    should_arrange: '應安排',
    rated: '已評價',
    should_evaluate: '應評價',
    should_submit: '應提交',
    arrange_plan: '安排計劃',
    evaluate_students: '評價學員',
    review_summary: '批閱總結',
    please_enter_name_account: '請輸入姓名/賬號',
    not_arranged: '未安排',
    pending_feedback: '待反饋',
    arrange_a_helping_program: '安排幫帶計劃',
    mentor_program: '導師計劃',
    arrange_help_tip: '學員反饋：學員已確認',
    arrange_help_input_tip: '請輸入您對該學員安排的幫帶計劃，例如希望學員在幫帶期間應參與的培訓課程，應掌握和提高的業務技能、能力，應承擔的工作，預計達成的目標等',
    excellent: '優秀',
    qualified: '合格',
    unqualified: '不合格',
    submit_summary_complete: '提交總結完成',
    approved_qualified: '批閱合格完成',
    summary_review: '總結批閱',
    require: '總結要求',
    total_score: '總結總分',
    passing_score: '合格總分',
    submitter: '提交人',
    summary_content: '總結內容',
    please_enter_a_comment: '請輸入評語',
    fill_score: '滿分{0}',
    num_score_pass: '{0}分合格',
    overdue: '逾期',
  },
  cm_view: '查看',
  demand: {
    call_for_infor: '需求徵集信息',
    please_enter_the_name_of_the_demand_collection: '請輸入需求徵集名稱',
    table: {
      solicitation_name: '需求徵集名稱',
      solicitation_year: '徵集年度',
      collection_time: '徵集時間',
      status: '狀態',
      operate: '操作',
    },
    call_for_requests: '徵集要求',
    add_demand: '新增需求',
    collection_has_not_started_yet: '徵集未開始',
    solicitation_has_ended: '徵集已結束',
    submit_record: '提交記錄',
    form: {
      year: '年度',
      training_name: '需求名稱',
      training_name_p: '請輸入需求名稱',
      training_form: '培訓形式',
      training_form_p: '請選擇培訓形式',
      holding_time: '舉辦時間',
      holding_time_p: '請選擇舉辦時間',
      requirements_catalog: '需求目錄',
      requirements_catalog_p: '請選擇需求目錄',
      training_budget: '預算費用',
      training_budget_p: '請輸入培訓預算費用',
      content_of_training_needs: '培訓需求內容',
      content_of_training_needs_p: '請輸入培訓需求內容',
      reason_for_training_need: '培訓需求理由',
      reason_for_training_need_p: '請輸入培訓需求理由',
    },
    requirement_reporting_record: '需求上報記錄',
    internal_training: '內部培訓',
    send_out_training: '派出培訓',
    add_save_tip: '提交成功，是否繼續提交需求？',
    batch_reporting: '批量上報',
    batch_delete: '批量刪除',
    budget: '預算費用',
    report_tip: '請選擇您要上報的需求',
    sure_repors: '確定要批量上報選中的需求數據嗎？',
    sure_repor: '確定要上報該需求數據嗎？',
    del_tip: '請選擇您要刪除的需求',
    sure_dels: '確定要批量刪除選中的需求數據嗎？',
    sure_del: '確定要刪除該需求數據嗎？',
    report_status: '上報狀態',
    reporting_time: '上報時間',
    reported: '已上報',
    not_reported: '未上報',
    requirement_information: '需求信息',
    reporting_information: '上報信息',
    develop_information: '製定信息',
    reportperson: '上報人',
    established: '已製定',
    not_formulated: '未製定',
    set_time: '製定時間',
    training_plan_name: '培訓計畫名稱',
    reporting_department: '上報部門',
    reporting: '上報',
    yuan: '元',
    tenThousandYuan: '萬元',
    please_enter_name: '請輸入名稱（50字以內）',
    please_enter_requirements: '請輸入培訓需求內容（500字以內）',
    please_enter_reason: '請輸入培訓需求理由（500字以內）',
    please_enter_budget_cost: '請輸入預算費用<100000000',
    requirement_saved_successfully: '需求保存成功',
    rejected: '已駁回',
    reasonForRejection2: '駁回理由',
    reasonForRejection: '駁回原因',
    rejectionTime: '駁回時間',
    rejectedBy: '駁回人',
  },
  overdue: '逾期',
  cm_add: '新增',
  cm_operate: '操作',
  confirm_prompt: '確認提示',
  hottest: '最熱',
  current_position: '當前位置',
  please_enter_keywords: '請輸入關鍵字',
  cm_refresh: '刷一刷',
  sorry_you_visited: '抱歉，您訪問的{0}',
  cm_door: '門',
  cm_field: '場',
  sure_submit: '確定提交嗎？',
  no_attachment: '暫無附件',
  knowledge: {
    knowledge_catalog: '知識庫目錄',
    no_catalog: '暫無目錄',
    knowledge_base_of: '知識庫情況',
    total_knowledge: '共有 | 個知識',
    knowledge_content: '知識庫內容',
    pic: '圖片',
    big_pic: '大圖',
    list: '列錶',
    intro: '知識簡介',
    sharer: '分享人員',
    table: {
      file_name: '知識庫名稱',
      file_type: '文件類型',
      downloads: '下載量',
      likes: '獲贊量',
      views: '瀏覽量',
      comments: '評論數',
      sharer: '分享人',
      upload_time: '上傳時間',
    },
    no_exist: '抱歉，您訪問的知識不存在。',
  },
  the_version_is_too_low_please_upgrade: '版本過低請升級',
  cm_views: '瀏覽量',
  cm_account: '賬號',
  cm_in_department: '所屬部門',
  cm_modify: '修改',
  cm_start: '開始',
  cm_end: '結束',
  system_prompt: '係統提示',
  pic: '圖',
  cm_ok: '好的',
  teacher: {
    calendar: '日程錶',
    online_courses: '線上課程',
    offline_class_schedule: '線下授課錶',
    live_class_schedule: '直播課程錶',
    lecturer_file: '講師檔案',
    lecturer_application_record: '講師申請記錄',
    student_area: '學員專區',
    total_today_nth_lesson: '今日共 | 節課',
    banner_tip: '企業內訓講師資源，可以快速瞭解企業師資力量',
    lecturer_infor: '講師信息',
    application_record: '申請記錄',
    historical_tenure: '歷史聘期',
    honor: '榮譽',
    avatar_tip: '圖片上傳比例為3:4，最佳上傳分辨率300*400',
    lecturer_level: '講師等級',
    lecturer_level_p: '請選擇講師等級',
    name: '姓名',
    lecturer_classification: '講師分類',
    lecturer_classification_p: '請選擇講師分類',
    sex: '性別',
    man_woman: '男 | 女',
    mobile: '手機號',
    email: '郵箱',
    tel: '座機號',
    position: '職務',
    birthday: '出生日期',
    inside: '內部',
    external: '外部',
    honor_description: '榮譽描述',
    lecturer_intro: '講師簡介',
    lecturer_intro_p: '請輸入講師簡介',
    motto: '座右銘',
    motto_p: '請輸入座右銘',
    teachingDirection: '授課方嚮',
    teachingDirection_p: '請輸入授課方嚮',
    expertAreas: '擅長領域',
    expertAreas_p: '請輸入擅長領域',
    expertTitle: '專家稱號',
    add_title: '添加稱號',
    researchAreas: '研究領域',
    researchAreas_p: '請輸入研究領域',
    workExperience: '工作簡歷',
    workExperience_p: '請輸入工作簡歷',
    others: '其它',
    others_p: '請輸入其他',
    upload_avatar: '上傳頭像',
    crop: '裁切',
    under_review: '審核中',
    rejected: '已駁回',
    apply_level: '申請等級',
    attachment: '課件附件',
    apply_status: '申請狀態',
    apply_time: '申請時間',
    take_effect: '生效',
    edit: '聘期修改',
    newly_hired: '新聘',
    renew: '續聘',
    promotion: '晉升',
    downgrade: '降級',
    fired: '解聘',
    honor_time: '榮譽時間',
    please_enter_a_certificate_name: '請輸入證書名稱',
    review_rejected: '審核駁回',
    review_pass: '審核通過',
    assessment_level: '評定等級',
    result: '聘期結果',
    evaluation_date: '評價日期',
    department: '所屬部門',
    unit: '所屬單位',
    lecturer_courseware: '講師課件',
    lecturer_courseware_p: '請上傳課件',
    lecturer_application_is_under_review_please_be_patient: '當前講師申請正在審核中，請耐心等待...',
    lecturer_application_information_audit_rejected_please_again: '講師申請信息審核駁回，請重新',
  },
  idp: {
    IDP_learning_plan: 'IDP學習計畫',
    supervisor: '監督人',
    learning_time: '學習時間',
    rate_of_learning: '學習進度',
    completion_status: '完成狀態',
    all: '全部',
    incomplete: '未完成',
    completed: '已完成',
    new_addition: '新增',
    edit: '編輯',
    delete: '刪除',
    view_details: '查看詳情',
    are_you_sure_you_want_to_delete_name: '確定要刪除{name}嗎？',
    add_idp_learning_plan: '添加IDP學習計畫',
    edit_idp_learning_plan: '編輯IDP學習計畫',
    basic_information: '基本信息',
    plan_year: '計劃年度',
    annual_learning_plan: '年度學習計畫',
    plan_name: '計劃名稱',
    plan_name_tips: '選擇年度後，自動生成固定的計劃名稱，規則為：年度+IDP，如“2024IDP”',
    start_date: '開始日期',
    end_date: '結束日期',
    choice: '選擇',
    supervisor_tips: '可跟蹤學習計劃的學習情況以及對學習內容中的考試進行批閱',
    learning_content: '學習內容',
    learning_content_tips: '學習內容中至少要添加一個學習資源',
    online_courses: '在線課程',
    curriculum: '課程',
    online_exams: '在線考試',
    examination: '考試',
    training_program: '培訓項目',
    project: '項目',
    homework: '實操作業',
    questionnaire: '問卷',
    save: '保存',
    return: '返回',
    confirm: '確定',
    cancel: '取消',
    select_supervisor: '選擇監督人',
    name_account: '姓名/賬號',
    account_name_plan_name: '賬號/姓名/計劃名稱',
    account: '賬號',
    department: '部門',
    name: '名稱',
    catalogue: '目錄',
    add_course: '添加課程',
    select_all_this_page: '全選本頁',
    selected: '已選',
    classification: '分類',
    add_exam: '添加考試',
    test_paper_name: '試卷名稱',
    select_test_paper: '選擇試卷',
    pass_line: '及格線',
    pass_line_tips: '及格分 = 試卷總分 * 及格線',
    exam_duration: '考試時長',
    exam_duration_tips: '註：考試答題可使用的時長',
    minute: '分鍾',
    exam_instructions: '考試說明',
    exam_instructions_1: '1、考試創建成功後，會根據試卷名稱生成對應的考試名稱;',
    exam_instructions_2: '2、考試的及格線來源試卷且不可更改；',
    exam_instructions_3: '3、學習計劃時間範圍內可以無限次參加考試；',
    exam_instructions_4: '4、答題過程中可以無限製切屏；',
    exam_instructions_5: '5、考試及格後才可查看考試記錄；',
    exam_instructions_6: '6、若試卷中存在問答題，需要監督人批閱，批閱後根據考試成績與及格成績比較判斷通過還是不通過，通過後考試才算完成；',
    add_test_paper: '添加試卷',
    exam_type: '試卷類型',
    fixed_test_paper: '固定試卷',
    random_test_paper: '隨機試卷',
    total_passing_score: '及格/總分',
    number_of_test_questions: '試題數',
    add_training_program: '添加培訓項目',
    add_homework: '添加作業',
    assignment_name: '作業名稱',
    homework_time: '作業時間',
    assignment_content: '作業內容',
    assignment_content_tips: '作業步驟中，文本內容和附件至少要有一項才能保存成功，作業步驟保存後，點擊確定才能提交作業，提交後的作業需計劃監督人進行批閱，批閱通過則作業才算完成',
    step_n: '第{number}步',
    step_n_tips: '（選填）請填寫作業步驟內容，提交長內容時，建議先使用客戶端軟件編輯好，再粘貼到這裏。',
    upload_attachments: '上傳附件',
    upload_attachment_tips: '支持圖片、文檔、音頻、視頻，最多可上傳5個附件，單個文件不超過200M',
    add_steps: '添加步驟',
    add_questionnaire_template: '添加問卷模闆',
    type: '類型',
    questionnaire_type: '問卷類型',
    vote: '投票',
    evaluation_form: '評估錶',
    survey_questionnaire: '調查問卷',
    student: '學員',
    complete_time: '完成時間',
    learning_plan_has_not_started_yet: '學習計畫未開始',
    learning_plan_has_ended: '學習計劃已結束',
    learning_plan_tracking: '學習計畫跟蹤',
    current_study_plan_already_has_exam_for_this_paper: '當前學習計畫已有該試卷的考試',
    complete_standard: '完成標準',
    reviewed_and_approved: '批閱通過',
    evaluation_settings: '考評設置',
    full_score_of_100_points: '滿分100分',
    assessment_setting_tips: '（開啓後，批閱人在批閱時要對學員的作業進行打分）',
    reviewer: '批閱人',
    select_personnel: '選擇人員',
    evaluation_form_evaluation: '評估錶評估',
    evaluator: '評估人',
    select_evaluation_form: '選擇評估錶',
    evaluate_status: '評估狀態',
  },
  not_over: '未結束',
  PracticeResults: "對練結果",
  PracticeDuration: "對練用時",
  PracticeStatus: "對練狀態",
  ResultAnalysis: "結果分析",
  MonitoringDetails: "監控詳情",
  AnalysisRadar: "分析雷達",
  dialogue_review: '對話回顧',
  cm_customer: '客戶',
  cm_sales: '銷售',
  cm_face_matching: '人臉匹配',
  cm_are_you_serious: '是否認真',
  cm_yes: '是',
  cm_no: '否',
  chapter_completion: '章節完成',
  homework_after_class: '課後作業',
  after_class_exam: '課後考試',
  after_class_assessment: '課後評估',
  class_assessment: '課程評估',
  star_review: '星評',
  cm_learning_data: '學習數據',
  cm_entry_time: '入職時間',
  cm_export: '導出',
  learningArchive: {
    StudyArchives: '的學習檔案',
    TotalNumberOfLogins: "登錄總次數",
    OnlineDuration: "在線時長",
    NumberOfSelfStudyCourses: "自學課程數",
    NumberOfTrainingPrograms: "培訓項目數",
    SelfStudyCourse: "自學課程",
    table: {
      serial: '序號',
      courseName: '課程名稱',
      courseDuration: '課程時長(分)',
      learningDuration: '學習時長(分)',
      studyProgress: '學習進度',
      startStudyTime: '開始學習時間',
      finalStudyTime: '最後學習時間',
    },
  },
  cm_ge: '個',
  downloadSuccessfully: '下載成功',
  networkErrMsg: '檢測到當前您的網路環境比較差，繼續學習會影響學習記錄的統計，建議您退出學習介麵！',
  getScoreWayTip: '獲得積分規則',
  ScoreTimeWayTip: '按合計次數給分通過一次獲得{scores}積分，合計最多{times}次可獲得積分',
  ScoreTimeWayTip2: '按合計次數給分通過一次獲得{scores}積分，不限次數',
  ScoreDayWayTip: '按每日次數給分每日通過一次獲得{scores}積分，每日最多{times}次可獲得積分',
  ScoreDayWayTip2: '按每日次數給分每日通過一次獲得{scores}積分，不限次數',
  ScoreNumberWayTip: '按答對個數給分 每答對一題獲得{scores}積分',
  FaceToFaceTeachingCannotBeEvaluated: '麵授還未開始，不能進行評估',
  downloadCenter: '下載中心',
  PleaseEnterFileName: '請輸入文件名',
  downloadCenterTips: '文件正在生成中，請稍後前往下載中心下載',
  expiresAfter3Days: "（3天後失效）",
  generating: "生成中",
  LastPage: "上一頁",
  NextPage: "下一頁",
  StartDownloading: "開始下載",
  InTotal: "共{total}條",
  LB_Error_ExamHasTraining: "後開始培訓",
  TrainingHasEnded: "培訓已結束",
  TrainingInProgress: "培訓進行中",
  total_class_hours: "總課時",
  remainder: '剩餘',
  expanded: '展開',
  unexpanded: '收起',
  expandedAll: '展開所有',
  unexpandedAll: '收起所有',
  informationCollection: '信息收集',
  charactersOrLess: '({0}字以內)',
  IdCard: "身份證",
  passport: "護照",
  TaiwanPass: "臺灣通行證",
  HongKongMacaoPass: "港澳通行證",
  city: "城市",
  ActivityInformationCollection: '活動信息收集',
  ExamInformationCollection: '考試信息收集',
  ParticipationInExams: '參與考試',
  FileNotPreview: '{type}文件不支持預覽',
  WheelOfFortune: '幸運大轉盤',
  LuckyWheelLottery: '幸運大轉盤抽獎',
  ScrapingCard: '刮刮卡',
  ScratchingCardLottery: '刮刮卡抽獎',
  RollingLottery: '滾動抽獎',
  MyLottery: '我的抽獎',
  LotteryTime: '抽獎時間',
  LotteryFormat: '抽獎形式',
  LotteryFormat2: '形式',
  WelcomeToLuckyLotteryEvent: '歡迎來到幸運大抽獎活動！',
  WelcomeToScratchCardLotteryEvent: '歡迎來到刮刮卡抽獎活動！',
  LotteryPeriod: '抽獎時間段',
  AwardDeadline: '領獎截止時間',
  AwardReceivingInstructions: '領獎說明',
  YouHaventWonYet: '您暫未中獎！',
  NLotteryOpportunities: '抽獎機會：{number}次',
  NumberOfPrizes: '獎品數量',
  LotteryDescription: '抽獎說明',
  AwardDescription: '獎項說明',
  WinningRecord: '中獎記錄',
  ContinueToScrapeRewards: '繼續刮獎',
  YouStillHaveNChancesToScrapePrize: '您還有{number}次刮獎機會',
  RedemptionCode: '兌獎碼',
  PleaseUpload: "請上傳",
  PleaseSelect: "請選擇",
  PleaseEnter: "請輸入",
  WithinNWords: "（{total}字以內）",
  SubmitForAward: "提交領獎",
  SubmitForAwardTips: "請於領獎截止時間前，憑兌獎碼領獎，逾期作廢。",
  ConfirmDoYouGiveUpWinning: "確定放棄此次中獎嗎？",
  RepeatedWinningIsNotAllowed: "不允許重複中獎",
  RepeatableWinning: "可重複中獎",
  LotteryHasNotStartedYet: "抽獎未開始",
  LotteryHasEnded: "抽獎已結束",
  LotteryOpportunityHasBeenUsedUp: "抽獎機會已用完",
  ThankYouForParticipating: "謝謝參與",
  WishingYouGoodLuck: "祝君好運",
  KeepWorkingHard: "再接再厲",
  DonNotLoseHeart: "不要灰心",
  NextTimeDefinitely: "下次一定",
  WinningImmediately: "馬上中獎",
  WinningTime: "中獎時間",
  PrizeName: "獎品名稱",
  PrizeImage: "獎品圖片",
  IsItIssued: "是否頒發",
  Issued: "已頒發",
  NotIssued: "未頒發",
  ToBeIssued: "待頒發",
  RedeemedPrize: "已兌獎",
  UnreclaimedPrize: "未兌獎",
  GoRedeemPrize: "去兌獎",
  Received: "已領",
  AwardCollection: "領獎",
  AuditCenter: "審核中心",
  IPostedIt: "我發起的",
  IAuditedIt: "我審核的",
  Withdrawn: "已撤回",
  TrainingPrograms: "培訓計劃",
  TrainingPlanImplementationNotice: "培訓計畫實施通知",
  TrainingPlanReviewNotice: "培訓計劃審核通知",
  ScheduledTime: "計劃時間",
  RevisionOfTrainingLeaders: "修改培訓負責人",
  Launch: "發起",
  Ends: "結束",
  Audits: "審核",
  ApprovalOpinions: "審批意見",
  NumPersonCanPass: "{num}人通過即可",
  NeedToPassAll: "需全部通過",
  TurnDown: "駁回",
  ConfirmType: "確認{type}嗎?",
  PleaseEnterAReviewComment: "請輸入審核意見（{type}）",
  Required: "必填",
  Optional: "選填",
  Retract: "撤回",
  RetractTip: "撤回後，可繼續編輯再提交發布審核",
  TrainingLeaders: "培訓負責人",
  NameOfTraining: "培訓名稱",
  TrainingLecturers: "培訓講師",
  SelectThePersonInCharge: "選擇負責人",
  PendingMyReview: "待我審核",
  ProgramDetails: "計劃明細",
  TrainingProgramDetails: "培訓計劃詳情",
  IHavePassed: "我已通過",
  IveDismissedIt: "我已駁回",
  AvatarCaptureAlert: "頭像採集提醒",
  AvatarCaptureAlertTip1: "請確保面部整潔乾淨，照片光線充足。",
  AvatarCaptureAlertTip2: "请确保摄像头中只有本人。",
  AvatarCaptureAlertTip3: "採集通過之後，如果對照片不滿意可以反覆採集，但保存後不可修改。",
  AvatarCaptureAlertTip4: "保存採集後，管理員通過審核後方才可以使用。",
  AvatarCaptureAlertTip5: "管理員審核後如果被駁回則可以再次採集本人人臉樣本。",
  GoSample: "去採樣",
  Resample: "重新採樣",
  StartSampling: "開始採樣",
  StartCollecting: "開始採集",
  GoCollect: "去採集",
  StartMatching: "開始匹配",
  MatchSuccessful: "匹配成功",
  MatchFail: "匹配失敗",
  Rematch: "重新匹配",
  Matching: "匹配中",
  Sampling: "採樣中",
  SampleSuccessful: "採樣成功",
  SampleFail: "採樣失敗",
  YouHaveNotCollectedFaceDataYet: '您暫未採集人臉數據？',
  matchStatusTip1: '匹配成功，請繼續學習',
  matchStatusTip2: '匹配失敗，將退出學習界面',
  matchStatusTip3: '匹配失敗，請繼續匹配',
  matchStatusTip4: '匹配失敗，還剩{n}次機會',
  VerificationPassesBeforeYouCanTakeTheExam: '{type}驗證通過才能參加考試',
  Location: '定位',
  IPErrorTip: "學員當前IP不在考試指定IP範圍內，無法參與考試!",
  LocationErrorTip: "學員當前定位不在考試設定的地址範圍內，無法參與考試!",
  VerificationPassed: "{type}驗證通過",
  Validating: "{type}驗證中...",
  CompletePersonnelInformation: "人員信息完善",
  NameAccount: "姓名（賬號）",
  NeedToImproveInformation: "需完善信息",
  ReasonForRejection: "駁回原因",
  Competition: "比賽",
  CompetitionDetails: "比賽詳情",
  SolicitationPeriod: "徵集期",
  ContestDeadline: "參賽截止",
  ScoringPeriod: "評分期",
  AnnouncementPeriod: "揭榜期",
  ActivityRules: "活動規則",
  Entries: "參賽作品",
  MyWork: "我的作品",
  EventName: "賽事名稱",
  ModifyingWorks: "修改作品",
  ModifyingWorksOpt1: "在“評分期”開始前，可修改自己上傳到作品",
  ModifyingWorksOpt2: "上傳作品後，不允許修改",
  CompetitionRules: "賽事規則",
  Contest: "參賽",
  CoverImage: "封麵圖",
  CoverWillBeDisplayedAs: "封麵將按{size}顯示",
  BestResolution: "最佳分辨率{size}",
  SupportingFormats: "支持格式{format}",
  VideoFileFormat: "視頻文件格式",
  AudioFileFormat: "音頻文件格式",
  DocumentFileFormat: "文檔文件格式",
  ImageFileFormat: "圖片文件格式",
  NoEntriesYet: "暫無參賽作品",
  LookingForwardToYourWork: "期待您的作品",
  EventRating: "賽事評分",
  NumberOfEntries: "參賽次數",
  Unrestricted: "無限製",
  PubTimes: "次",
  Subtitle: "副標題",
  FaceMatchErrorTip: "人臉識別不通過，請確定是否是本人或者重新採集照片",
  FaceMatchErrorTip2: "人臉識別不通過，將強制交卷",
  FaceMatchErrorTip3: "人臉識別不通過，請去專家庫系統更新照片",
  ExamRuleFaceTip: "考試中人臉識別不通過系統會強制交卷，且本次考試無效。",
  FaceRecognitionRecords: "人臉識別記錄",
  NumberOfIdentifications: "識別次數",
  BeforeTheExam: "考試前",
  DuringAnExam: "考試中",
  Success: "成功",
  Error: "失敗",
  FaceDataReviewInProgress: "人臉數據審核中",
  FailedFaceDataAudit: "人臉數據審核不通過",
  Unrated: "未評分",
  Rated: "已評分",
  Uploader: "上傳者",
  ToRate: "去評分",
  ViewRatings: "查看評分",
  JudgeRating: "評委評分",
  MicroCourseCompetition: "微課大賽",
  ExpertTraining: "專家培訓",
  CompetitionRatingHasEndedUnableToRate: "賽事評分已結束，不能評分",
  CompetitionRatingHasNotStartedYetUnableToRate: "賽事評分未開始，不能評分",
  PermissionToSitForExamsAfterPassing: "通過後是否允許參加考試",
  Permissible: "允許",
  Impermissible: "不允許",
  HavePassedTheExamAndAreNotAllowedToTakeItAgain: "已通過考試，不允許再次參與考試",
  PleaseLogInToTheExpertPoolSystemToUpdateYourPhotoInformation: "請登錄專家庫系統更新照片信息",
  ExamsAreCompletedWhenYouHandInYourPapers: "考試交卷即完成",
  AvatarCaptureAlertTip6: '為了確保人臉識別通過，建議在識別過程中搖搖頭、眨眨眼或者張張嘴',
  PleaseShakeYourHead: "請搖搖頭",
  PleaseBlink: "請眨眨眼",
  PleaseOpenYourMouth: "請張張嘴",
  TrainingNotes: "培訓須知",
  AccountPasswordLogin: "賬號密碼登錄",
  CaptchaLogin: "驗證碼登錄",
  FaceLogin: "人臉識別",
  ThirdLogin: "第三方登錄",
  MarkAllAsRead: "全部標記為已讀",
  MarkAllAsReadConfirm: "此操作會將未讀的消息全部標記為已讀",
  InternalMail: "站內信",
  n_mandatory_resources_need_to_be_completed: "需完成{number}個必修資源",
  n_elective_resources_need_to_be_completed: "需完成{number}個選修資源",
  AttendanceAddress: "考勤地址",
  TenThousandYuan: "萬元",
  IHaventWornMedalYet: "冇有可佩戴的勳章哦~",
  EnrollmentInfor: "報名信息",
  Province: "省份",
  District: "區縣",
  NotWithinTheTimeframe: "未在{type}時間範圍內",
  Consult: "諮詢",
  AdviceOnIssues: "問題諮詢",
  PleaseDescribeYourProblem: "請描述你的問題",
  QAHelperCatalogTip: "您好，您可以點擊下面常見問題分類，快速定位問題獲取幫助:",
  QAHelperAnswerTip: "您好，【{type}】有以下相關的問題供您參考:",
  QAHelperAnswerTip2: "對不起，您的提問暫時沒有合適的回答，我們會盡快完善問題庫，更好的給您提供幫助",
  QAHelperAnswerTip3: "對不起，當前分類中暫無問題，我們會盡快完善問題庫，為您提供更好的幫助",
  CourseNotes: "課程筆記",
  AddNotes: "添加筆記",
  SureYouWantToDeleteTheNote: "確定要刪除該條筆記嗎？",
  ContentCannotBeEmpty: "內容不能為空",
  supplement: "補",
  YouCanLearnBeforeYouStart: "開始前可學",
  YouCanLearnAtTheEnd: "結束後可學",
  NotYetDueForCourse: "未到課程學習時間",
  CourseDurationHasEnded: "課程學習時間已結束",
  StudyStartTime: "學習開始時間",
  StudyEndTime: "學習結束時間",
  MaterialFilingName: "材料備案名稱",
  TransmissionUnit: "報送單位",
  TransmissionOfMaterial: "報送材料",
  TrainingNotice: "培訓通知",
  Roster: "名單",
  Summarize: "總結",
  CourseSchedule: "課程安排",
  TrainMaterialFilling: "培訓材料備案",
  Courseware: "課件",
  CoursewareTip: "發佈後，課件中的附件會同步上傳到素材庫中",
  CompleteCourseChapters: "完成課程章節",
  CompleteCourseExam: "{type}課程考試",
  CompleteCourseAssignments: "{type}課程作業",
  CompleteCourseEvaluation: "{type}課程評價",
  PassIsCompleted: "及格即完成",
  SubmitIsCompleted: "提交即完成",
  ApprovedIsCompleted: "批閱即完成",
  AfterTheCompleteCourseCanBeExam: "學完課程可開考",
  QualificationCertificate: "資質證書",
  NoScannedCopyOfCertificate: "該學員暫無證書掃描件",
  DangerousGoodsCertificate: "危險品證書",
  latestCompletedTraining: "最新一次完成的培訓",
  lastCompletedTraining: "上次完成的培訓",
  certificateNumber: "證書編號",
  trainingCategory: "培訓類別",
  dateOfIssue: "發證日期",
  grades: '成績',
  trainingInstitutions: "培訓機構",
  trainingInstitutionAddress: "培訓機構地址",
  trainingMaterials: "培訓教材",
  instructor: "教員",
  evaluator2: "評估員",
  auditor2: "審核員",
  certificateExpirationDate: "證書到期日",
  initialOrRefresherTraining: "初訓或複訓",
  trainingPlanNotImplemented: "未實施培訓計畫",
  trainingProgramName: "培訓計劃名稱",
  hostOrganization: "主辦單位",
  numberNotImplemented: "未實施數",
  RemarksModifications: "備註修改意見",
  CanInputNDecimalPlace: "可輸入{total}位小數",
  PleaseEnterInteger: "請輸入整數",
  CertificateName: "證書名稱",
  ExpirationDate: "到期時間",
  ReminderOfQualificationCertificateExpiration: "資質證書到期提醒",
  TrainingClassroomReservation:"培訓教室預約",
  PlaceClassRoomName: "教室名稱",
  AppointmentTime:"預約時間",
  ReservationName: "預約人姓名",
  ContactNumber: "聯繫電話",
  ApplicationDescription:"預約用途",
  LB_Exam_RatingQuestion: "評分題",
  LB_Exam_PictureSingleChoice: "圖片單選",
  LB_Exam_PictureMultipleChoice: "圖片多選",
  LB_Exam_CombinationEvaluationQuestion: "組合評價題",
  LB_Exam_ScaleQuestions: "量錶題",
  LB_Exam_TheVote:"票數",
  LB_Exam_TheRatio:"比例",
  LB_Exam_Option: "選項",
  LearningType: "學習類型",
  Enrollment: "報名學習",
  EnrollmentTime: "報名時間",
  NotYetDueForEnrollment: "未到報名時間",
  CourseName: "課程名稱",
  CourseType: "課程類型",
  CourseTypeTips: "標準課程是上傳標準的ScormZIP包，ZIP包要添加壓縮到根目錄",
  NonStandardCourses: "非標準課程",
  Scorm12Course: "Scorm1.2標準課程",
  VRCourse: "VR課程",
  CourseId: "課程編號",
  CourseLecturer: "課程講師",
  Labels: "標籤",
  CourseDevelopmentTime: "課程開發時間",
  SectionContent: "章節內容",
  Size: "大小",
  SetStudyPeriods: "设置课程学习时间范围",
  PeriodOfStudy: "學習期限",
  PeriodOfStudyRise: "學習期限起",
  PeriodOfStudyStop: "學習期限止",
  LearnBeforeTheCourseStarts: "課程開始前可學",
  LearnAfterTheCourse: "課程結束後可學",
  TheStartTimeCannotBeGreaterThanTheEndTime: "開始時間不能大於結束時間",
  LearningSettings: "學習設置",
}